import React, { PropsWithChildren, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Theme,
} from '@mui/material';
import { StockItem } from 'src/types/api';
import { StockItemPrice } from './StockItemPrice';

interface Props {
  items?: StockItem[];
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      button: {
        cursor: 'pointer',
        backgroundColor: 'unset',
        border: 'unset',
        color: 'currentColor',
      },
    }),
  { name: 'StockItemsDialog' },
);

export const StockItemsDialog: React.FunctionComponent<
  PropsWithChildren<Props>
> = ({ items, children }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({});

  return (
    <>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogContent>
          {items?.map(item => (
            <Grid container key={item?.id} spacing={2}>
              <Grid item xs={8}>
                {item?.warehouse?.address?.line}
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end">
                  {item?.publicStock}
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end">
                  <StockItemPrice offerPrice={item?.offerPrice} />
                </Box>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Закрыть</Button>
        </DialogActions>{' '}
      </Dialog>
      <button onClick={() => setOpen(true)} className={classes?.button}>
        {children}
      </button>
    </>
  );
};
