import { PrintDocument, request } from 'src/shared/api';
import { apiRoutesMap } from 'src/shared/config';

export const getRealizationDocumentsPrint = (guid: string) =>
  request.get<PrintDocument[]>(
    apiRoutesMap.realizationDocumentsPrint.getRoute(guid),
  );

export const getZakazesDocumentsPrint = (guid: string) =>
  request.get<PrintDocument[]>(
    apiRoutesMap.zakazesDocumentsPrint.getRoute(guid),
  );
