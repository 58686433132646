import React, { PropsWithChildren, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  Toolbar,
} from '@mui/material';
import clsx from 'clsx';
import {
  Search,
  Menu,
  ChevronLeft,
  ExitToApp,
  Home,
  Warning,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { routes, routesMap } from 'src/routes';

import { useRouter } from 'src/hooks/useRouter';
import {
  apiService,
  useGetOrders,
  useGetUserMe,
} from 'src/services/apiService';
import { checkIsPermitted } from 'src/entities/user';
import { useGetSusOrders } from 'src/entities/order';

interface Props {}

const drawerWidth = 240;

const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        display: 'flex',
      },
      appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      hide: {
        display: 'none',
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        // space for bottom navbar
        paddingBottom: '60px',
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
      },
      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
      toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    }),
  { name: 'MainLayout' },
);

export const MainLayout: React.FunctionComponent<PropsWithChildren<Props>> = ({
  children,
}) => {
  //@ts-ignore
  const classes = useStyles();
  const { push } = useRouter();
  const [query, setQuery] = useState('');

  const [open, setOpen] = React.useState(false);

  const userRequest = useGetUserMe();

  const susOrdersRequest = useGetSusOrders();

  const ordersRequest = useGetOrders({
    variables: { state: 'review' },
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () =>
    apiService.v1Api.loginDelete().then(
      () => push('/login'),
      () => push('/login'),
    );

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(open && classes.hide)}
            >
              <Menu />
            </IconButton>
          </Box>
          <Box width="100%" pl={2}>
            <TextField
              fullWidth
              variant="outlined"
              value={query}
              onChange={e => setQuery(e?.target?.value)}
              onKeyDown={e =>
                e.key === 'Enter' &&
                query &&
                typeof window !== 'undefined' &&
                window
                  ?.open(
                    `${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products?query=${query}`,
                    '_blank',
                  )
                  ?.focus()
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!query}
                      onClick={() =>
                        query &&
                        typeof window !== 'undefined' &&
                        window
                          ?.open(
                            `${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products?query=${query}`,
                            '_blank',
                          )
                          ?.focus()
                      }
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {Boolean(ordersRequest.data?.data?.length) && (
            <Box px={2}>
              <IconButton
                href={`${routesMap.orders.getRoute()}?state=review`}
                color="warning"
                title='Требуется обратка заказов'
              >
                <Box
                  width={24}
                  height={24}
                  display="flex"
                  alignItems="center"
                  justifyItems="center"
                >
                  <Warning />
                </Box>
              </IconButton>
            </Box>
          )}
                    {Boolean(susOrdersRequest.data?.data?.length) && (
            <Box px={2}>
              <IconButton
                href="https://api.instock-dv.ru/v1_api_private/sus_orders"
                color="error"
              >
                <Box
                  width={24}
                  height={24}
                  display="flex"
                  alignItems="center"
                  justifyItems="center"
                >
                  <Warning />
                </Box>
              </IconButton>
            </Box>
          )}
          <Box px={2}>
            <IconButton href="http://instock-dv.ru/">
              <Box
                width={24}
                height={24}
                display="flex"
                alignItems="center"
                justifyItems="center"
              >
                <img src="/logo/logo_1.svg" style={{ width: '100%' }} />
              </Box>
            </IconButton>
          </Box>
          <Box display="flex" alignItems="center">
            {userRequest?.data?.firstName ||
              userRequest?.data?.email ||
              userRequest?.data?.phoneNumber}
            <Box ml={2}>
              <IconButton
                color="inherit"
                aria-label="logout"
                title="Выйти"
                onClick={handleLogout}
              >
                <ExitToApp />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <List>
          {routes
            .filter(
              item =>
                item.menuPath &&
                checkIsPermitted(item.roles, userRequest.data?.roles),
            )
            .map(
              item =>
                item.menuPath && (
                  <ListItem
                    button
                    component={Link}
                    to={item.menuPath}
                    key={item.path}
                  >
                    <ListItemText primary={item.title} />
                  </ListItem>
                ),
            )}
        </List>
        <Divider />
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <Inbox /> : <Mail />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
};
