import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link, Paper, Theme } from '@mui/material';
import { Table } from 'src/components/common/Table';
import { useRouter } from 'src/hooks/useRouter';
import { routesMap } from 'src/routes';
import useSWR, { mutate } from 'swr';
import { apiService } from 'src/services/apiService';
import { Warehouse } from 'src/types/api';

interface Props {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'WarehousesPage' },
);

export const WarehousesPage: React.FunctionComponent<Props> = ({}) => {
  const classes = useStyles({});

  const { push, query } = useRouter();

  const requestQuery = {
    limit: query.limit || 10,
    offset: query.offset || 0,
  };

  const swrKey = [
    apiService.v1Api.usersList.name,
    requestQuery.limit,
    requestQuery.offset,
  ];

  const { data, isValidating } = useSWR(
    swrKey,
    () =>
      apiService.v1Api
        .warehousesList(requestQuery)
        .then((res: any) => res.data),
    { revalidateOnFocus: false },
  );

  const handleOnChange = (params: { page: number; limit: number }) => {
    const nextRequestQuery = {
      ...requestQuery,
      offset: params.page * params.limit,
      limit: params.limit,
    };

    push(routesMap.warehouses.getRoute(), nextRequestQuery);
  };

  return (
    <div className={classes.root}>
      <Paper>
        <Table
          title="Склады"
          headers={[
            {
              title: 'id',
              component: 'th',
              scope: 'row',
              align: 'right',
              extract: (item: Warehouse) =>
                item.id && (
                  <Link
                    href={routesMap.warehouse.getRoute(item.id)}
                    target="_blank"
                  >
                    {item.id}
                  </Link>
                ),
            },
            {
              title: 'Название',
              extract: (item: Warehouse) => item.name,
            },
            {
              title: 'Поставщик',
              extract: (item: Warehouse) => item.supplier?.name,
            },
            {
              title: 'Адрес',
              extract: (item: Warehouse) => item.address?.line,
            },
          ]}
          data={data?.data}
          totalCount={data?.totalCount}
          onFetch={() => mutate(swrKey)}
          onChange={handleOnChange}
          loading={isValidating}
          limit={parseInt(requestQuery.limit, 10)}
          offset={parseInt(requestQuery.offset, 10)}
        />
      </Paper>
    </div>
  );
};
