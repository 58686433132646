import React, { PropsWithChildren } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, BottomNavigation as BottomNavigationMui } from '@mui/material';

interface Props {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        overflow: 'hidden',
        zIndex: 100,
        borderRadius: '8px',
        position: 'fixed',
        bottom: '8px',
        left: '50%',
        transform: 'translate(-50%, 0)',
        border: '1px solid grey',
      },
    }),
  { name: 'BottomNavigation' },
);

export const BottomNavigation: React.FunctionComponent<
  PropsWithChildren<Props>
> = ({ children, ...rest }) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <BottomNavigationMui showLabels {...rest}>
        {children}
      </BottomNavigationMui>
    </div>
  );
};
