import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Theme, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { AddressSelect } from '../address-select';
import { typeAddress } from 'src/types/gqlTypes';
import { apiService, useGetWarehouse } from 'src/services/apiService';
import { mutate } from 'swr';
import { EntityTitle } from '../EntityTitle';
import { Address } from 'src/shared/api';

interface Props {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'WarehousePage' },
);

export const WarehousePage: React.FunctionComponent<Props> = ({}) => {
  const classes = useStyles({});

  const { warehouseId } = useParams<{ warehouseId: string }>();

  const warehouse = useGetWarehouse({ warehouseId });

  // const [update] = useUpdateWarehouseAddress({ warehouseId });

  const handelChangeAddress = (address: Address) => {
    if (warehouse.data?.id)
      apiService.v1Api
        .warehousesAddressUpdate(warehouse.data?.id.toString(), address)
        .then(() => warehouse.refetch());
  };

  return (
    <div className={classes.root}>
      <EntityTitle id={warehouse.data?.id} title={warehouse.data?.name} />
      <Box mt={2}>
        <Typography variant="body1">
          {warehouse.data?.supplier?.name}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body1">
          {warehouse.data?.address?.line
            ? warehouse.data?.address?.line
            : 'Адрес не указан'}{' '}
          <AddressSelect onSave={handelChangeAddress} />
        </Typography>
      </Box>
    </div>
  );
};
