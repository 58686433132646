import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@mui/material';
import { Autocomplete } from './common/Autocomplete';
import { orderBy } from 'lodash';
import { Contractor, Outlet } from 'src/shared/api';

import { useDebounce } from 'src/hooks/useDebounce';

interface Props {
  onSelect?: (contractor: Outlet | null) => any;
  outlets?: Outlet[] | null;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'OutletSelect' },
);

export const OutletSelect: React.FunctionComponent<Props> = ({
  onSelect,
  outlets,
}) => {
  const classes = useStyles({});
  const [query, setQuert] = useState('');

  return (
    <div className={classes.root}>
      <Autocomplete
        label="Торговая точка"
        options={orderBy(outlets || [], ['name'])}
        onChange={(e, value: Contractor) => onSelect && onSelect(value)}
        onChangeTextField={e => setQuert(e.target.value)}
        getOptionLabel={option => {
          return `${option.address.line} (${option.name} )`;
        }}
      />
    </div>
  );
};
