import React from 'react';
import { PrintOrderDocumens } from './PrintOrderDocumens';
import { getRealizationDocumentsPrint } from '../api';

interface Props {
  guid: string;
}

export const PrintRealizationDocumens: React.FunctionComponent<Props> = ({
  guid,
}) => {
  return (
    <PrintOrderDocumens guid={guid} fetcher={getRealizationDocumentsPrint} />
  );
};
