import React, { PropsWithChildren } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, CircularProgress, Fade, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import red from '@material-ui/core/colors/red';

interface Props {
  loading: boolean;
  onCancel?: any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
      },
      progressAnimation: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
      cancelButton: {
        color: red[500],
        opacity: 0.5,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -21,
        marginLeft: -21,
        '&:hover': {
          opacity: 1,
        },
      },
      icon: {
        width: '18px',
        height: '18px',
      },
    }),
  { name: 'WithLoading' },
);

export const WithLoading: React.FunctionComponent<PropsWithChildren<Props>> = ({
  loading,
  onCancel,
  children,
}) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Fade in={!loading} timeout={{ enter: 300, exit: 800 }}>
        <div>{children}</div>
      </Fade>
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '800ms' : '0ms',
        }}
        unmountOnExit
      >
        <div>
          <CircularProgress size={24} className={classes.progressAnimation} />
          {onCancel && (
            <Fade
              in={loading}
              style={{
                transitionDelay: '3000ms',
              }}
            >
              <IconButton className={classes.cancelButton} onClick={onCancel}>
                <Close className={classes.icon} />
              </IconButton>
            </Fade>
          )}
        </div>
      </Fade>
    </div>
  );
};
