import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Link, TablePagination, Theme } from '@mui/material';
import { ProductsPage } from './ProductsPage';
import { useRouter } from 'src/hooks/useRouter';
import { useGetCategoriesMissingProducts } from 'src/entities/category';
import { routesMap } from 'src/routes';
import { PRODUCT_HOST } from 'src/shared/config/config';

interface Props {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'MissingCategoriesProducts' },
);

export const MissingCategoriesProducts: React.FunctionComponent<
  Props
> = ({}) => {
  const classes = useStyles({});
  const router = useRouter();

  const variables = {
    limit: parseInt(router?.query?.limit, 10) || 50,
    offset: parseInt(router?.query?.offset, 10) || 0,
  };

  const onChange = (value: any) => {
    router.push(routesMap.categoriesMissingProducts.getRoute(), {
      ...variables,
      offset: value * variables.limit,
    });
  };
  const categoriesMissesProducts = useGetCategoriesMissingProducts({
    variables,
  });

  const products = categoriesMissesProducts?.data?.data
    ?.map(item => item.bcode)
    ?.filter(item => item);

  const missingProductsCount =
    categoriesMissesProducts?.data?.data?.length &&
    products &&
    categoriesMissesProducts?.data?.data?.length - products?.length;

  return (
    <Box>
      <Box>
        {Boolean(missingProductsCount) && (
          <Box>Без продуктов: {missingProductsCount}</Box>
        )}

        <Box>
          <Link
            href={`${PRODUCT_HOST}/products?ids=${products?.join(',')}`}
            target="_blank"
          >
            Продукты: {products?.length}
          </Link>
        </Box>
      </Box>
      <TablePagination
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} из ${count !== -1 ? count : `больше чем ${to}`}`
        }
        // labelRowsPerPage="элементов на странице"
        count={categoriesMissesProducts.data?.totalCount || 1}
        rowsPerPage={variables.limit}
        page={variables.offset / variables.limit}
        rowsPerPageOptions={[]}
        onPageChange={(e, value) => onChange(value)}
      />
    </Box>
  );
};
