import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Button, TextField, Box } from '@mui/material';
import { useRouter } from 'src/hooks/useRouter';
import { apiService } from 'src/services/apiService';
import useSWR, { mutate } from 'swr';

interface Props {
  redirect?: string;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        maxWidth: '300px',
      },
      message: {
        minHeight: '50px',
      },
    }),
  { name: 'LoginForm' },
);

export const LoginForm: React.FunctionComponent<Props> = ({ redirect }) => {
  const classes = useStyles({});

  const { push, location } = useRouter();

  const { mutate } = useSWR(apiService.v1Api.usersMeList.name, () =>
    apiService.v1Api.usersMeList().then(res => res.data),
  );

  const [state, setState] = useState({
    login: '',
    password: '',
  });
  const [error, setError] = useState<null | string>();

  const handleSignIn = () =>
    apiService.v1Api.loginCreate(state).then(
      res => {
        mutate(res.data);
        if (redirect === '/login' || location.pathname === '/login') {
          push('/');
        }
        redirect && push(redirect);
      },
      res => {
        setError(res?.error?.message);
      },
    );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="90vh"
    >
      <div className={classes.root}>
        <TextField
          variant="outlined"
          fullWidth
          label="Логин"
          value={state.login}
          onChange={e => setState({ ...state, login: e.target.value })}
        />
        <Box mt={2}>
          <TextField
            variant="outlined"
            fullWidth
            label="Пароль"
            value={state.password}
            type="password"
            onChange={e => setState({ ...state, password: e.target.value })}
            onKeyPress={e => e.key === 'Enter' && handleSignIn()}
          />
        </Box>
        <Box mt={2}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleSignIn}
          >
            Войти
          </Button>
        </Box>

        <Box
          mt={2}
          className={classes.message}
          display="flex"
          justifyContent="center"
        >
          {error}
        </Box>
      </div>
    </Box>
  );
};
