import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Link } from '@mui/material';

interface Props {
  index: number;
  item: any;
  config?: {
    renderItem?: (props: { item: any; index: number }) => any;
  };
}

export const EnlityListItem: React.FunctionComponent<Props> = ({
  index,
  item,
  config,
}) => {
  return config?.renderItem ? (
    config.renderItem({ item, index })
  ) : (
    <ListItem key={item?.id} divider>
      <ListItemIcon>{index}</ListItemIcon>
      <ListItemText
        primary={
          <Link href={item.href}>{Object.values(item.data).join(', ')}</Link>
        }
      />
    </ListItem>
  );
};
