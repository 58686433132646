import React, { useMemo } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
} from '@mui/material';
import { Orders } from 'src/components/orders';
import { useRouter } from 'src/hooks/useRouter';
import { routesMap } from 'src/routes';
import { useGetOrders } from 'src/services/apiService';
import { OrdersCharts } from '../../../components/OrdersCharts';
import { ContractorSelect } from '../../../components/ContractorSelect';
import { checkIsPermitted, useGetUserMe } from 'src/entities/user';
import { UserRole } from 'src/shared/api';
import { removeEmptyProps } from 'src/helpers/removeEmtyProperties';
import { OrderWithMeta } from 'src/types/api';
import { MenuItem } from '@material-ui/core';

interface Props {}

export const OrdersPage: React.FunctionComponent<Props> = ({}) => {
  const { query, ...router } = useRouter();

  const userMeRequest = useGetUserMe();

  const outlets = query.outlets?.join(',');

  const variables = removeEmptyProps({
    limit: parseInt(query.limit, 10) || 100,
    offset: parseInt(query.offset, 10) || 0,
    state: query.state || 'processed',
    outlets,
    users:
      !checkIsPermitted([UserRole.ROLE_ADMIN], userMeRequest.data?.roles) &&
      !outlets
        ? userMeRequest.data?.id
        : null,
  });

  const request = useGetOrders({
    variables,
  });

  const handleOnChange = (data: any) =>
    push({
      ...variables,
      offset: data.page * data.limit,
      limit: data.limit,
    });

  const push = (query: any) =>
    router.push(`${routesMap.orders.getRoute()}?${new URLSearchParams(query)}`);

  const orders = useMemo(() => {
    if (!query.isMissedDocs && !query.isErrors) return request?.data?.data;
    const items = request?.data?.data || [];
    let filtered: OrderWithMeta[] = [];

    if (query.isMissedDocs) {
      filtered = items?.filter(
        order =>
          !order?.zakazesCount ||
          order?.zakazesCount !== order?.realizationsCount,
      );
    }
    if (query.isErrors) {
      filtered = items?.filter(order => order?.errorsCount);
    }

    return Array.from(
      new Map(filtered.map(order => [order.data.id, order])).values(),
    ).sort((a, b) =>
      a?.data?.id && b?.data?.id && a?.data?.id > b?.data?.id ? -1 : 1,
    );
  }, [request.data, query.isMissedDocs, query.isErrors]);

  if (query?.charts) {
    return <OrdersCharts />;
  }

  return (
    <div>
      <Box mb={2} display="flex" alignItems="center">
        <FormControlLabel
          label="Несозданные документы"
          checked={query.isMissedDocs}
          onChange={() =>
            router.push(
              routesMap.orders.getRoute(),
              removeEmptyProps({
                ...query,
                isMissedDocs: query.isMissedDocs ? null : true,
              }),
            )
          }
          control={<Checkbox />}
        />
        <FormControlLabel
          label="Ошибки"
          checked={query.isErrors}
          onChange={() =>
            router.push(
              routesMap.orders.getRoute(),
              removeEmptyProps({
                ...query,
                isErrors: query.isErrors ? null : true,
              }),
            )
          }
          control={<Checkbox />}
        />
        <FormControl>
          <InputLabel id="state-label">Статус</InputLabel>
          <Select
            labelId="state-label"
            value={variables.state}
            label="Age"
            onChange={e => push({ ...variables, state: e.target.value })}
          >
            <MenuItem value="draft">draft</MenuItem>
            <MenuItem value="review">review</MenuItem>
            <MenuItem value="processed">processed</MenuItem>
            <MenuItem value="deleted">deleted</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <ContractorSelect
          onSelect={contarctor =>
            push({
              outlets:
                contarctor?.outlets?.map(item => item.id)?.join(',') || '',
            })
          }
        />
      </Box>
      <Box mt={2}>
        <Orders
          data={orders || []}
          loading={request.isLoading}
          totalCount={request?.data?.totalCount}
          // onFetch={() => refetch(variables)}
          onChange={handleOnChange}
          limit={variables.limit}
          offset={variables.offset}
        />
      </Box>
    </div>
  );
};
