import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@mui/material';
import { User as IUser } from 'src/shared/api';

interface Props {
  user: IUser;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'User' },
);

export const UserPreview: React.FunctionComponent<Props> = ({ user }) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      {[
        [user.lastName, user.firstName, user.fatherName]
          .filter(item => item)
          .join(' '),
        user.phoneNumber,
      ]
        .filter(item => item)
        .join(', ')}
    </div>
  );
};
