import React from 'react';
import { createArticle, useGetArticles } from 'src/entities/article';
import { EntityList } from 'src/shared/ui/entity-view/EntityList';
import { routesMap } from 'src/routes';
import { Article, ArticlePropName } from 'src/shared/api';
import {
  ListItemText,
  Link,
  ListItemSecondaryAction,
  Checkbox,
  Grid,
  ListItem,
  Dialog,
} from '@mui/material';
import { format } from 'date-fns';
import { EntityCreate } from 'src/shared/ui/entity-create';

interface Props {}

const config = {
  renderItem: (props: {
    item: { data: Article; key: number; href: string };
    index: number;
  }) => (
    <ListItem key={props?.item?.data?.id} divider>
      <ListItemText
        primary={<Link href={props.item.href}>{props.item.data.title}</Link>}
        secondary={format(
          new Date(props.item.data.startTime),
          'yyyy-MM-dd HH:mm:ss',
        )}
      />
      <ListItemSecondaryAction>
        <Checkbox checked={props.item.data.published} disabled />
      </ListItemSecondaryAction>
    </ListItem>
  ),
};

export const ArticlesPage: React.FunctionComponent<Props> = ({}) => {
  const articlesRequest = useGetArticles();

  const onCreate = (data: any) => createArticle(data).then(articlesRequest.mutate);

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <EntityCreate
          item={{
            title: '',
            published: false,
            body: '',
            startTime: format(new Date(), 'yyyy-MM-dd hh:MM:ss'),
          }}
          config={{
            onChange: onCreate,
            skipUnconfigured: true,
            props: {
              title: {
                editable: true,
                label: ArticlePropName.title,
              },
            },
          }}
        />
        <EntityList
          items={articlesRequest?.data?.data?.map(item => ({
            data: item,
            key: item.id,
            href: routesMap.article.getRoute(item.id),
          }))}
          config={config}
        />
      </Grid>
    </Grid>
  );
};
