import React from 'react';

import { checkIsPermitted, useGetUserMe } from 'src/entities/user';
import { UserRole } from 'src/shared/api';

interface Props {
  roles: UserRole[];
}

export const PermissionWrapper: React.FunctionComponent<React.PropsWithChildren<
  Props
>> = ({ children, roles }) => {
  const userRequest = useGetUserMe();

  if (checkIsPermitted(roles, userRequest.data?.roles)) return <>{children}</>;

  return null;
};
