import { ResponseWithMeta, request } from 'src/shared/api';
import { apiPublicRoutesMap } from 'src/shared/config';
import { Product } from 'src/types/api';

export const requestCodesByBarcodes = (barcodes: Array<string | number>) =>
  request.post<ResponseWithMeta<{ [key: number]: Product }>>(
    apiPublicRoutesMap.searchProductsByBarcodes.getRoute(),
    {
      barcodes,
    },
  );
