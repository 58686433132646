import {
  request,
  useRequest,
  ResponseWithMeta,
  Article,
  RequestHookParams,
} from 'src/shared/api';
import { apiRoutesMap } from 'src/shared/config';

export const createArticle = (data: Partial<Article>) =>
  request.post<any>(apiRoutesMap.articles.getRoute(), data);

export const changeArticle = (id: number | string, data: Partial<Article>) =>
  request.patch<any>(apiRoutesMap.article.getRoute(id), data);

export const useGetArticles = () =>
  useRequest<ResponseWithMeta<Article[]>>(apiRoutesMap.articles.getRoute());

export const useGetArticle = (
  params: RequestHookParams<{ id: number | string }>,
) =>
  useRequest<Article>(
    apiRoutesMap.article.getRoute(params.variables.id),
    params,
  );
