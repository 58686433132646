import { Box, Grid } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { changeArticle, useGetArticle } from 'src/entities/article';
import { useRouter } from 'src/hooks/useRouter';
import { Article, ArticlePropName } from 'src/shared/api';
import { EntityControl } from 'src/shared/ui/entity-control/EntityControl';

interface Props {}

export const ArticlePage: React.FunctionComponent<Props> = ({}) => {
  const router = useRouter();

  const articleRequest = useGetArticle({
    variables: { id: router.query.articleId },
  });

  const article = articleRequest.data && {
    ...articleRequest.data,
    createdAt: format(new Date(articleRequest.data.createdAt), 'yyyy-MM-dd'),
  };

  const onChange = (data: Partial<Article>) =>
    changeArticle(router.query.articleId, data).then(articleRequest.mutate);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={3}>
        <EntityControl
          item={article}
          config={{
            onChange,
            invertEditable: true,
            props: {
              id: {
                editable: false,
              },
              title: {
                label: ArticlePropName.title,
              },
              body: {
                label: ArticlePropName.body,
                type: 'text',
              },
              createdAt: {
                editable: false,
                label: ArticlePropName.createdAt,
              },
              startTime: {
                label: ArticlePropName.startTime,
                type: 'date',
              },
              published: {
                label: ArticlePropName.published,
              },
              updatedAt: {
                skip: true,
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '&  img': {
              maxWidth: '1024px',
              maxHeight: '384px',
              marginBottom: '16px',
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '1024px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                fontSize: '24px',
                marginBottom: '8px',
              }}
            >
              {articleRequest.data?.title}
            </Box>
            {articleRequest.data?.body && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  '&  p': {
                    marginTop: '2px',
                    marginBottom: '2px',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: articleRequest.data?.body }}
              />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
