import { TextField } from '@mui/material';
import React from 'react';

interface Props {
  label?: string;
  value?: string | null;
  editable?: boolean | null;
  onChange: (value: string) => any;
}

export const StringControl: React.FunctionComponent<Props> = ({
  label,
  value,
  editable,
  onChange,
}) => {
  const handleChange = (e: any) => onChange(e.target.value);

  return (
    <TextField
      fullWidth
      label={label}
      value={value}
      variant="outlined"
      disabled={!editable}
      onChange={handleChange}
    />
  );
};
