import React, { useEffect, useMemo, useState } from 'react';
import { BooleanControl } from './BooleanControl';
import { StringControl } from './StringControl';
import { NumberControl } from './NumberControl';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from '@mui/material';
import { TextControl } from './TextControl';
import { DateControl } from './DateControl';

interface PropConfig {
  label?: string;
  editable?: boolean;
  skip?: boolean;
  type?: 'text' | 'date';
  render?: (props: { value: any; onChange?: (value: any) => any }) => any;
  required?: boolean;
}

interface Config {
  invertEditable?: boolean;
  skipUnconfigured?: boolean;
  editable?: boolean;
  collapsedProps?: string[];
  onChange?: (data: any) => Promise<any> | void;
  props?: {
    [keys: string]: PropConfig;
  };
  conponentPros?: any;
  actions?: any;
  state?: any;
}

export interface Props {
  item: any;
  config?: Config;
}

interface RenderControlProps {
  label?: string;
  propName: string;
  value?: any;
  config?: PropConfig;
  editable?: boolean;
  onChange?: any;
}

const RenderControl: React.FunctionComponent<RenderControlProps> = ({
  value,
  config,
  propName,
  onChange,
}) => {
  const handleChange = (value: any) => onChange({ [propName]: value });

  if (config?.skip) return null;

  if (config?.render) {
    return config?.render({ value, onChange: handleChange });
  }

  if (config?.type === 'text') {
    return (
      <TextControl
        label={config?.label}
        value={value}
        editable={config?.editable}
        onChange={handleChange}
      />
    );
  }

  if (config?.type === 'date') {
    return (
      <DateControl
        label={config?.label}
        value={value}
        editable={config?.editable}
        onChange={handleChange}
      />
    );
  }

  if (typeof value === 'boolean') {
    return (
      <BooleanControl
        label={config?.label}
        value={value}
        editable={config?.editable}
        onChange={handleChange}
      />
    );
  }

  if (typeof value === 'number') {
    return (
      <NumberControl
        label={config?.label}
        value={value}
        editable={config?.editable}
        onChange={handleChange}
      />
    );
  }

  if (typeof value === 'string') {
    return (
      <StringControl
        label={config?.label}
        value={value}
        editable={config?.editable}
        onChange={handleChange}
      />
    );
  }

  return (
    <div>
      {config?.label}: {value}
    </div>
  );
};

const isPropInCollapsed = (
  propName: string | void,
  collapsedProps: string[] | void,
) => Boolean(propName && collapsedProps?.includes(propName));

export const EntityControl: React.FunctionComponent<Props> = ({
  item,
  config = {
    collapsedProps: [],
    editable: true,
  },
}) => {
  const [state, setState] = useState<any>({});

  useEffect(() => {
    if (config.state) {
      setState(config.state);
    }
  }, [config.state]);

  const changed = useMemo(
    () =>
      Object.entries(state)
        .map(stateItem => item?.[stateItem[0]] === stateItem[1])
        .some(equal => !equal),
    [state, item],
  );

  const editable = Boolean(config.invertEditable);

  const visiblePropsKeys = useMemo(
    () =>
      Object.keys(item || {}).filter(key =>
        config.skipUnconfigured
          ? config?.props?.[key] &&
            !isPropInCollapsed(key, config?.collapsedProps)
          : !isPropInCollapsed(key, config?.collapsedProps),
      ),
    [item, config.skipUnconfigured, config?.props, config.collapsedProps],
  );

  const handleChange = (data: any) =>
    setState((prev: any) => ({ ...prev, ...data }));

  const handleSave = () =>
    config?.onChange && config?.onChange(state)?.then(() => setState({}));

  const isAllRequiredPropsFilled =
    config.props &&
    Object.keys(config.props)
      .filter(key => config?.props && config.props[key]?.required)
      .every(key => state[key] !== null && state[key] !== undefined);

  console.log(isAllRequiredPropsFilled);
  return (
    <Box>
      {visiblePropsKeys?.map(key => (
        <Box key={key} mb={2}>
          <RenderControl
            propName={key}
            value={state?.[key] ?? item?.[key]}
            onChange={handleChange}
            config={{
              ...(config.props?.[key] || {}),
              label: config.props?.[key]?.label || key,
              editable:
                typeof config.props?.[key]?.editable === 'boolean'
                  ? config.props?.[key]?.editable
                  : editable,
            }}
          />
        </Box>
      ))}
      {Boolean(config.collapsedProps?.length) && (
        <Accordion>
          <AccordionSummary>Детали</AccordionSummary>
          <AccordionDetails>
            <Box>
              {config.collapsedProps?.map(key => (
                <Box key={key} mb={2}>
                  <RenderControl
                    propName={key}
                    value={state?.[key] ?? item?.[key]}
                    onChange={handleChange}
                    config={{
                      ...(config.props?.[key] || {}),
                      label: config.props?.[key]?.label || key,
                      editable: config.editable
                        ? typeof config.props?.[key]?.editable === 'boolean'
                          ? config.props?.[key]?.editable
                          : editable
                        : false,
                    }}
                  />
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      <Box mt={2} display="flex">
        {item && (
          <Button
            disabled={!changed || !isAllRequiredPropsFilled}
            onClick={handleSave}
            variant="contained"
            color="primary"
          >
            Сохранить
          </Button>
        )}
        {config.actions?.map((item: any, i: number) => (
          <Box key={i} ml={2}>
            {item}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
