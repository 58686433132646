import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import React, { useState } from 'react';
import { useGetOrderDocuments } from '../model';
import { WithLoading } from 'src/components/common/WithLoading';

interface Props {
  guid: string;
  fetcher: any;
}

export const PrintOrderDocumens: React.FunctionComponent<Props> = ({
  guid,
  fetcher,
}) => {
  const [open, setOpen] = useState(false);
  const documentsRequest = useGetOrderDocuments(fetcher, guid);

  const handleClick = () => {
    setOpen(true);
    documentsRequest.get();
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Button onClick={handleClick} variant="contained" color="primary">
        Скачать документы
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <WithLoading loading={documentsRequest.loading}>
            <List>
              {documentsRequest.loading && (
                <ListItem>
                  <ListItemText primary="Загрузка" />
                </ListItem>
              )}
              {documentsRequest.documents?.map(item => (
                <ListItem key={item.url}>
                  <Link href={item.url} download target="_blank">
                    {item.title}
                  </Link>
                </ListItem>
              ))}
            </List>
          </WithLoading>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
