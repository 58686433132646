import {
  request,
  RequestHookParams,
  ResponseWithMeta,
  useRequest,
} from 'src/shared/api';
import { apiRoutesMap } from 'src/shared/config';

export const makeReserveRequest = (id: number | string) =>
  request.post<any>(apiRoutesMap.makeReserve.getRoute(id));

export const useGetSusOrders = (params?: RequestHookParams<never>) =>
  useRequest<ResponseWithMeta<any[]>>(
    apiRoutesMap.susOrders.getRoute(),
    params,
  );

export const deleteOrderRequest = (id: number | string) =>
  request.delete<any>(apiRoutesMap.order.getRoute(id));
