import React, { PropsWithChildren, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Image } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { Button, Theme, ButtonBase, Box } from '@mui/material';

interface Props {
  lablel?: string;
  onChange: (files: File[]) => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      },
    }),
  { name: 'FileUpload' },
);

export const FileUpload: React.FunctionComponent<PropsWithChildren<Props>> = ({
  lablel,
  children,
  onChange,
}) => {
  const classes = useStyles({});
  const [files, setFiles] = useState<File[]>([]);

  const onDrop = (filesList: any) => {
    const files = Array.from(filesList || []) as File[];
    if (onChange) onChange(files);
    setFiles(files);
  };

  const { getInputProps, getRootProps } = useDropzone({
    onDrop,
  });

  return (
    <div className={classes.root}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {children ? (
          <ButtonBase>{children}</ButtonBase>
        ) : (
          <Button variant="outlined">
            <Image /> <Box ml={2}>{lablel}</Box>
          </Button>
        )}
      </div>
    </div>
  );
};
