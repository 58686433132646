import { useRequestWithPayload } from 'src/hooks/useRequestWithPayload';
import { typeDadataAddress } from 'src/types/appTypes';
import { typeAddress } from 'src/types/gqlTypes';

const token = '9bae6ec99ea63e8e2b8db9ca65d313b9482cd6fd';

const options = {
  headers: {
    Authorization: `Token ${token}`,
  },
  withCredentials: false,
};

export function useGetSuggestionsAddresses() {
  return useRequestWithPayload<
    { query: string; count: number },
    {
      suggestions?: typeDadataAddress[];
    }
  >({
    url: `${process.env.REACT_APP_ADDRESS_SUGGESTIONS_HOST}/suggestions/api/4_1/rs/suggest/address`,
    method: 'post',
    options,
  });
}

export function useCreateAddresses() {
  return useRequestWithPayload<any, typeAddress>({
    url: `${process.env.REACT_APP_API_HOST}/v1_api/addresses`,
    method: 'put',
    options,
  });
}
