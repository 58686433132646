import useSWR from 'swr';
import axios from 'axios';

const fetcher = axios.create({
  withCredentials: true,
});

export const request = {
  get: <T>(input: string) => fetcher.get<T>(input).then(res => res.data),
  post: <T>(input: string, init?: any) =>
    fetcher.post<T>(input, init).then(res => res.data),
  patch: <T>(input: string, init?: any) =>
    fetcher.patch<T>(input, init).then(res => res.data),
  put: <T>(input: string, init?: any) =>
    fetcher.put<T>(input, init).then(res => res.data),
  delete: <T>(input: string) => fetcher.delete<T>(input).then(res => res.data),
};

type RequestQueryParamsType = Record<string | number, any>;

const addQueryParam = (query: RequestQueryParamsType, key: string) => {
  return (
    encodeURIComponent(key) +
    '=' +
    encodeURIComponent(
      Array.isArray(query[key]) ? query[key].join(',') : query[key],
    )
  );
};

export const stringifyQueryParams = (
  rawQuery?: RequestQueryParamsType,
): string => {
  const query = rawQuery || {};

  const keys = Object.keys(query).filter(
    key =>
      typeof query[key] !== 'undefined' &&
      !['', null, 'null'].some(value => query[key] === value),
  );

  return keys.length
    ? `?${keys
        .map(key =>
          typeof query[key] === 'object' && !Array.isArray(query[key])
            ? stringifyQueryParams(query[key]).substring(1)
            : addQueryParam(query, key),
        )
        .join('&')}`
    : '';
};

export const addQueryParams = ({
  href,
  query = {},
}: {
  href: string;
  query?: RequestQueryParamsType;
}): string => `${href}${stringifyQueryParams(query)}`;

export interface RequestByLimitOffset {
  limit?: number | string | null;
  offset?: number | string | null;
}

export type ResponseWithMeta<T> = { data: T; totalCount?: number };

export type RequestHookParams<T> = { variables: T; skip?: boolean };

export const useRequest = <T>(href: string, params?: RequestHookParams<any>) =>
  useSWR<T>(
    params?.skip ? null : addQueryParams({ href, query: params?.variables }),
    url => request.get<T>(url),
    {
      revalidateOnFocus: false,
    },
  );
