import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@mui/material';
import { EntityCreate } from 'src/shared/ui/entity-create';
import { CallTask, CallTaskPropName } from 'src/shared/api';
import { UserSelect } from 'src/components/user-select';
import { createCallTask } from '../api';

interface Props {
  onCreate: () => any;
  label?: string;
  state?: Partial<CallTask>;
  requiredProps?: string[]
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'CallTaskCreate' },
);

export const CallTaskCreate: React.FunctionComponent<Props> = ({
  onCreate,
  label,
  state,
  requiredProps = []
}) => {
  const classes = useStyles({});

  const handleCreate = (data: any) =>
    createCallTask({ ...data, executor: data.executor.id }).then(onCreate);

  return (
    <EntityCreate
      item={{
        name: '',
        phonenumber: '',
        executor: null,
        scheduleTime: null,
        comment: null,
      }}
      labelButtonCreate={label}
      config={{
        state,
        onChange: handleCreate,
        skipUnconfigured: true,
        props: {
          name: {
            editable: true,
            label: CallTaskPropName.name,
            required: true,
          },
          phonenumber: {
            editable: true,
            label: CallTaskPropName.phonenumber,
            required: true,
          },
          scheduleTime: {
            editable: true,
            label: CallTaskPropName.scheduleTime,
            type: 'date',
            required: true,
          },
          executor: {
            editable: true,
            label: CallTaskPropName.executor,
            render: ({ onChange, value }) => <UserSelect onChange={onChange} user={value}/>,
            required: true,
          },
          comment: {
            editable: true,
            label: CallTaskPropName.comment,
            type: 'text',
          },
        },
      }}
    />
  );
};
