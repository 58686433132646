import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Grid,
  Link,
  ListItem,
  ListItemText,
  Theme,
} from '@mui/material';
import { format } from 'date-fns';
import { useRouter } from 'src/hooks/useRouter';

//entities
import {
  useGetCallTask,
  changeCallTask,
  PhoneCall,
  CallTaskCreate,
} from 'src/entities/call';

//shared
import { CallTask, CallTaskPropName } from 'src/shared/api';
import { EntityControl } from 'src/shared/ui/entity-control';
import { User, UserPreview, useGetUser } from 'src/entities/user';
import { useGetUsers } from 'src/services/apiService';

interface Props {}

const config = {
  renderItem: (props: {
    item: { data: CallTask; key: number; href: string };
    index: number;
  }) => (
    <ListItem key={props?.item?.data?.id} divider>
      <ListItemText
        primary={<Link href={props.item.href}>{props.item.data.name}</Link>}
        secondary={format(
          new Date(props.item.data.createdAt),
          'yyyy-MM-dd HH:mm:ss',
        )}
      />
    </ListItem>
  ),
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'CallTaskPage' },
);

export const CallTaskPage: React.FunctionComponent<Props> = ({}) => {
  const classes = useStyles({});

  const router = useRouter();

  const callTasksRequest = useGetCallTask({
    variables: { id: router.query.callId },
  });

  const usersRequest = useGetUsers({
    query: callTasksRequest.data?.data.phonenumber,
    skip: !callTasksRequest.data?.data,
  });

  const userRequest = useGetUser({
    variables: { id: usersRequest.data?.data?.[0]?.id as number },
    skip: usersRequest.data?.data?.length !== 1,
  });

  const onChange = (data: Partial<CallTask>) =>
    changeCallTask(router.query.callId, data).then(callTasksRequest.mutate);

  const handleCallBack = () => onChange({ closed: true });

  const callBackButton = (
    <CallTaskCreate
      label="Перезвонить"
      onCreate={handleCallBack}
      state={{
        phonenumber: callTasksRequest.data?.data?.phonenumber,
        executor: callTasksRequest.data?.data?.executor,
      }}
    />
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={4}>
        <EntityControl
          item={callTasksRequest.data?.data}
          config={{
            onChange,
            editable: !callTasksRequest.data?.data?.closedTime,
            collapsedProps: [
              'id',
              'status',
              'creator',
              'executor',
              'updatedAt',
              'closedTime',
            ],
            actions: [callBackButton],
            props: {
              createdAt: {
                label: CallTaskPropName.createdAt,
                type: 'date',
              },
              scheduleTime: {
                label: CallTaskPropName.scheduleTime,
                type: 'date',
              },
              closedTime: {
                label: CallTaskPropName.closedTime,
                type: 'date',
              },
              name: {
                label: CallTaskPropName.name,
              },
              phonenumber: {
                label: CallTaskPropName.phonenumber,
                render: props => (
                  <Link
                    href={`callto:${props.value};extId=${callTasksRequest.data?.data.id}`}
                  >
                    {props.value}
                  </Link>
                ),
              },
              comment: {
                label: CallTaskPropName.comment,
                type: 'text',
                editable: true,
              },
              creator: {
                render: props => (
                  <Box>
                    {CallTaskPropName.creator}
                    <Box>
                      {props.value && <UserPreview user={props.value} />}
                    </Box>
                  </Box>
                ),
              },
              executor: {
                render: props => (
                  <Box>
                    {CallTaskPropName.executor}
                    <Box>
                      {props.value && <UserPreview user={props.value} />}
                    </Box>
                  </Box>
                ),
              },
              phoneCall: {
                render: props => <PhoneCall phoneCall={props.value} />,
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12} lg={8}>
        <User user={userRequest.data?.data} onChanage={userRequest.refetch} />
      </Grid>
    </Grid>
  );
};
