import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@mui/material';

interface Props {
  id?: string | number | null;
  title?: string | number | null;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      muted: {
        color: theme.palette.grey[500],
      },
    }),
  { name: 'EntityTitle' },
);

export const EntityTitle: React.FunctionComponent<Props> = ({ id, title }) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Typography variant="h5">
        {id && <span className={classes.muted}>#{id}</span>} {title}
      </Typography>
    </div>
  );
};
