import React from 'react';
import { ContractorsPage } from 'src/components/pages/ContractorsPage';
import { UsersPage } from 'src/components/pages/UsersPage';
import { ContractorPage } from 'src/components/pages/ContractorPage';
import { UserPage } from 'src/components/pages/UserPage';
import { WarehousesPage } from 'src/components/pages/WarehousesPage';
import { WarehousePage } from 'src/components/pages/WarehousePage';
import { PromotionsPage } from 'src/components/pages/PromotionsPage';
import { PromotionPage } from 'src/components/pages/PromotionPage';
import { OrderPage } from 'src/components/pages/OrderPage';
import { ProductsPage } from 'src/components/pages/ProductsPage';
import { UserRole, Route } from 'src/shared/api';

//pages
import { OrdersPage } from 'src/pages/orders';
import { ArticlesPage } from './pages/articles';
import { ArticlePage } from './pages/article';
import { CallTaskPage } from './pages/call-task';
import { CallTasksPage } from './pages/call-tasks';
import { ReportsPage } from './pages/reports';
import { SmartTaskPage } from './pages/smart-task';
import { CategoriesPage } from './components/pages/CategoriesPage';
import { MissingCategoriesProducts } from './components/pages/MissingCategoriesProducts';

export const routesMap: { [key: string]: Route } = {
  index: {
    route: '/',
    getRoute: () => '/',
    roles: [UserRole.ROLE_MANAGER, UserRole.ROLE_ADMIN],
  },
  users: {
    route: '/users',
    getRoute: () => '/users',
    title: 'Пользователи',
    roles: [UserRole.ROLE_ADMIN],
  },
  user: {
    route: '/users/:userId',
    getRoute: (userId: number | string) => `/users/${userId}`,
    roles: [UserRole.ROLE_ADMIN],
  },
  contractors: {
    route: '/contractors',
    getRoute: () => '/contractors',
    title: 'Контрагенты',
    roles: [UserRole.ROLE_MANAGER, UserRole.ROLE_ADMIN],
  },
  contractor: {
    route: '/contractors/:contractorId',
    getRoute: (contractorId: number | string) => `/contractors/${contractorId}`,
    title: 'Контрагент',
    roles: [UserRole.ROLE_MANAGER, UserRole.ROLE_ADMIN],
  },
  warehouses: {
    route: '/warehouses',
    getRoute: () => '/warehouses',
    title: 'Склады',
    roles: [UserRole.ROLE_ADMIN],
  },
  warehouse: {
    route: '/warehouses/:warehouseId',
    getRoute: (warehouseId: number | string) => `/warehouses/${warehouseId}`,
    roles: [UserRole.ROLE_ADMIN],
  },
  orders: {
    route: '/orders',
    getRoute: () => '/orders',
    title: 'Заказы',
    roles: [UserRole.ROLE_MANAGER, UserRole.ROLE_ADMIN],
  },
  order: {
    route: '/orders/:orderId',
    getRoute: (orderId: number | string) => `/orders/${orderId}`,
    roles: [UserRole.ROLE_MANAGER, UserRole.ROLE_ADMIN],
  },
  promotions: {
    route: '/promotions',
    getRoute: () => '/promotions',
    title: 'Акции',
    roles: [UserRole.ROLE_ADMIN],
  },
  promotion: {
    route: '/promotions/:promotionId',
    getRoute: (promotionId: number | string) => `/promotions/${promotionId}`,
    roles: [UserRole.ROLE_ADMIN],
  },
  promotionInitiator: {
    route: `/promotion-initiators/:promotionInitiatorId`,
    getRoute: (promotionInitiatorId: number | string) =>
      `/promotion-initiators/${promotionInitiatorId}`,
    roles: [UserRole.ROLE_ADMIN],
  },
  promotionInitiators: {
    route: '/promotion-initiators',
    getRoute: () => `/promotion-initiators`,
    title: 'Инициаторы акций',
    roles: [UserRole.ROLE_ADMIN],
  },
  product: {
    route: '/products/:productId',
    getRoute: (productId: number | string) => `/products/${productId}`,
    roles: [UserRole.ROLE_ADMIN],
  },
  products: {
    route: '/products',
    getRoute: () => `/products`,
    roles: [UserRole.ROLE_ADMIN],
  },
  // marketplaces: {
  //   route: '/marketplaces',
  //   getRoute: () => `/marketplaces`,
  //   title: 'Маркетплейсы',
  //   roles: [UserRole.ROLE_ADMIN],
  // },
  article: {
    route: '/articles/:articleId',
    getRoute: (articleId: number | string) => `/articles/${articleId}`,
    roles: [UserRole.ROLE_ADMIN],
  },
  articles: {
    route: '/articles',
    getRoute: () => `/articles`,
    title: 'Статьи',
    roles: [UserRole.ROLE_ADMIN],
  },
  callTask: {
    route: '/callTasks/:callId',
    getRoute: (callId: number | string) => `/callTasks/${callId}`,
    roles: [UserRole.ROLE_ADMIN],
  },
  callTasks: {
    route: '/callTasks',
    getRoute: () => `/callTasks`,
    title: 'Звонки',
    roles: [UserRole.ROLE_ADMIN],
  },
  reports: {
    route: '/reports',
    getRoute: () => '/reports',
    title: 'Отчеты',
    roles: [UserRole.ROLE_ADMIN],
  },
  smarttask: {
    route: '/smarttask',
    getRoute: () => '/smarttask',
    title: 'SMART задча',
    roles: [UserRole.ROLE_ADMIN],
  },
  categories: {
    route: '/categories',
    getRoute: () => '/categories',
    title: 'Категории',
    roles: [UserRole.ROLE_ADMIN],
  },
  categoriesMissingProducts: {
    route: '/categories/missing_products',
    getRoute: () => '/categories/missing_products',
    title: 'Продукты без категорий',
    roles: [UserRole.ROLE_ADMIN],
  },
};

export const routes = [
  {
    path: routesMap.index.route,
    component: <div>Добро пожаловать!</div>,
    roles: routesMap.index.roles,
  },
  {
    path: routesMap.contractor.route,
    component: <ContractorPage />,
    roles: routesMap.contractor.roles,
  },
  {
    path: routesMap.orders.route,
    component: <OrdersPage />,
    title: routesMap.orders.title,
    menuPath: routesMap.orders.route,
    roles: routesMap.orders.roles,
  },
  {
    path: routesMap.contractors.route,
    component: <ContractorsPage />,
    title: routesMap.contractors.title,
    menuPath: routesMap.contractors.route,
    roles: routesMap.contractors.roles,
  },
  {
    path: routesMap.user.route,
    component: <UserPage />,
    roles: routesMap.user.roles,
  },
  {
    path: routesMap.users.route,
    component: <UsersPage />,
    title: routesMap.users.title,
    menuPath: routesMap.users.route,
    roles: routesMap.users.roles,
  },
  {
    path: routesMap.order.route,
    component: <OrderPage />,
    roles: routesMap.order.roles,
  },
  {
    path: routesMap.warehouse.route,
    component: <WarehousePage />,
    roles: routesMap.warehouse.roles,
  },
  {
    path: routesMap.promotions.route,
    component: <PromotionsPage />,
    title: routesMap.promotions.title,
    menuPath: `${routesMap.promotions.route}?time=present`,
    roles: routesMap.promotions.roles,
  },
  {
    path: routesMap.promotion.route,
    component: <PromotionPage />,
    roles: routesMap.promotion.roles,
  },
  {
    path: routesMap.products.route,
    component: <ProductsPage />,
    roles: routesMap.products.roles,
  },
  {
    path: routesMap.warehouses.route,
    component: <WarehousesPage />,
    title: routesMap.warehouses.title,
    menuPath: routesMap.warehouses.route,
    roles: routesMap.warehouses.roles,
  },
  // {
  //   path: routesMap.marketplaces.route,
  //   component: <MarketplacesPage />,
  //   title: routesMap.marketplaces.title,
  //   menuPath: routesMap.marketplaces.route,
  //   roles: routesMap.marketplaces.roles,
  // },
  {
    path: routesMap.article.route,
    component: <ArticlePage />,
    roles: routesMap.article.roles,
  },
  {
    path: routesMap.articles.route,
    component: <ArticlesPage />,
    title: routesMap.articles.title,
    menuPath: routesMap.articles.route,
    roles: routesMap.articles.roles,
  },
  {
    path: routesMap.callTask.route,
    component: <CallTaskPage />,
    roles: routesMap.callTask.roles,
  },
  {
    path: routesMap.callTasks.route,
    component: <CallTasksPage />,
    title: routesMap.callTasks.title,
    menuPath: routesMap.callTasks.route,
    roles: routesMap.callTasks.roles,
  },
  {
    path: routesMap.smarttask.route,
    component: <SmartTaskPage />,
    title: routesMap.smarttask.title,
    menuPath: routesMap.smarttask.route,
    roles: routesMap.smarttask.roles,
  },
  {
    path: routesMap.reports.route,
    component: <ReportsPage />,
    title: routesMap.reports.title,
    menuPath: routesMap.reports.route,
    roles: routesMap.reports.roles,
  },
  {
    path: routesMap.categories.route,
    component: <CategoriesPage />,
    title: routesMap.categories.title,
    menuPath: routesMap.categories.route,
    roles: routesMap.categories.roles,
  },
  {
    path: routesMap.categoriesMissingProducts.route,
    component: <MissingCategoriesProducts />,
    roles: routesMap.categoriesMissingProducts.roles,
  },
];
