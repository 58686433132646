import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

interface Props {
  label?: string;
  value?: boolean | null;
  editable?: boolean | null;
  onChange: (value: boolean) => any;
}

export const BooleanControl: React.FunctionComponent<Props> = ({
  label,
  value,
  onChange,
  editable,
}) => {
  const handleChange = (e: any, value: boolean) => onChange(value);

  return (
    <FormControlLabel
      checked={Boolean(value)}
      control={<Checkbox />}
      disabled={!editable}
      label={label}
      onChange={handleChange}
    />
  );
};
