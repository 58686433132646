/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Address, Contractor, User } from 'src/shared/api';

/**
 * Product
 */
export interface Product {
  code?: number;
  barcodes?: string[];
  title?: string;
  brand?: Brand;
  series?: Series;
  manufacturer?: Manufacturer;
  defaultImage?: ProductImage;
  colorImage?: ProductImage;
  images?: ProductImage[];
  description?: string;
  howToUse?: string;
  ingredients?: string;
  vendorCode?: string;
  stockItems?: StockItem[];
  numericCharacteristics?: any;
  boolCharacteristics?: any;
  stringCharacteristics?: any;

  /** Цена в копейках */
  price?: number;
}

/**
 * Брэнд
 */
export interface Brand {
  id?: number;
  name?: string;
  adaptedName?: string;
}

/**
 * Брэнд
 */
export interface Manufacturer {
  id?: number;
  name?: string;
}

/**
 * Картинка продукта
 */
export interface ProductImage {
  id?: number;
  uri?: string;
  height?: number;
  width?: number;
}

/**
 * Серия
 */
export interface Series {
  id?: number;
  name?: string;
}

export interface Cart {
  cartElements?: CartElement[];
  totalCost?: number;
  outlet?: Outlet;
  approvedDeliveryDay?: string;
  offerTime?: string;
}

export interface ZakazLine {
  id?: number;
  totalCost?: number;
  offerPrice?: number;
  product?: Product;
  quantity?: number;
}

export interface Zakaz {
  idInternal: string;
  guid: string;
  createdAt: string;
  totalCost: number;
  lines: OrderLine[];
  realizationDocument: Realization | null;
  lastRequest: string | null;
  reserveIdInternal: string | null;
}

export interface OrderWithMeta {
  data: Order;
  realizationsCount: number;
  zakazesCount: number;
  errorsCount: number;
}

export interface OrderProp {
  enabled: boolean;
  selected: string;
  values: { [key: string]: string };
}

export enum OrderState {
  Draft = 'Draft',
  PendingConfiramtion = 'PendingConfiramtion',
  Confirmed = 'Confirmed',
  PendingReview  = 'PendingReview',
}

export interface Order {
  id?: number;
  creator?: User;
  outlet?: Outlet;
  lines?: OrderLine[];
  totalCost?: number;
  createdAt?: string;
  updatedAt?: string;
  comment?: string | null;
  uncoveredLines?: OrderLine[];
  zakazes?: Zakaz[];
  lastRequest: string | null;
  paymentMethod?: OrderProp;
  paymentKind?: OrderProp;
  state: OrderState;
  isPickup?: boolean;
  payOnReceive?: {
    enabled: boolean;
    selected: boolean;
    values: string[];
  };
}
export interface Realization {
  guid?: string;
  idInternal?: string;
  outlet?: Outlet;
  items?: OrderLine[];
  totalBaseCost: number;
  totalCost: number;
  saved?: number;
  paymentDate?: string;
  createdAt?: string;
  arrivalDate?: string;
}

export interface OrderLine {
  id?: number;
  singlePrice?: number;
  totalCost?: number;
  offerPrice?: number;
  singlePriceInKopeck?: number;
  product?: Product;
  quantity?: number;
  orderLine?: {
    id?: number;
    product?: Product;
    requestedQuantity?: number;
    singlePriceInKopeck?: number;
    totalCost?: number;
  };
}

export interface Category {
  id?: number;
  name?: string;
  lft?: number;
  lvl?: number;
  rgt?: number;
  parent?: Category;
  description?: string;
}

export interface Outlet {
  id?: number;
  vendorId?: string;
  contractor?: Contractor;
  address?: Address;
  createdAt?: string;
  updatedAt?: string;
}

export interface Warehouse {
  id?: number;
  name?: string;
  supplier?: Supplier;
  vendorId?: string;
  address?: Address;
  isPickUpPoint?: boolean;
}

export interface CartElement {
  quantity?: number;
  createdAt?: string;
  updatedAt?: string;
  totalCost?: number;
  product?: Product2;
  singlePrice?: number;
}

export interface Supplier {
  alias?: string;
  name?: string;
}

export interface Product2 {
  code?: number;
  productId?: number;
  name?: string;
  barcodes?: string[];
  stockItems?: StockItem[];
  createdAt?: string;
  updatedAt?: string;
}

export interface StockItem {
  id?: number;
  warehouse?: Warehouse;
  vendorId?: string;
  offerPrice?: number;
  publicStock?: number;
  product?: Product;
  createdAt?: string;
  updatedAt?: string;
}

export interface Promotion {
  id?: number | string;
  start?: string;
  finish?: string;
  type?: string;
  vendorId?: string;
  vendorName?: string;
  title?: string | null;
  description?: string | null;
  caption?: string | null;
  banner?: any;
  wobbler?: any;
  backgroundColor?: string | null;
  multiplicityCondition?: number;
  multiplicityEachCondition?: number;
}

export interface PromotionInitiator {
  id?: number | string;
  vendorName?: string;
  title?: string | null;
  description?: string | null;
  caption?: string | null;
  banner?: any;
  wobbler?: any;
  backgroundColor?: string | null;
  promotions: Promotion[];
}

export interface OrderLog {
  id: number;
  response: string;
  request: string;
}
export interface ZakazLog {
  id: number;
  response: string;
  request: string;
}

export type RequestParams = Omit<RequestInit, 'body' | 'method'> & {
  secure?: boolean;
};

export type RequestQueryParamsType = Record<string | number, any>;

interface ApiConfig<SecurityDataType> {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => RequestParams;
}

interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D | null;
  error: E | null;
}

enum BodyType {
  Json,
  FormData,
}

class HttpClient<SecurityDataType> {
  public baseUrl: string = '';
  private securityData: SecurityDataType = null as any;
  private securityWorker: null | ApiConfig<SecurityDataType>['securityWorker'] =
    null;

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private addQueryParam(query: RequestQueryParamsType, key: string) {
    return (
      encodeURIComponent(key) +
      '=' +
      encodeURIComponent(
        Array.isArray(query[key]) ? query[key].join(',') : query[key],
      )
    );
  }

  protected addQueryParams(rawQuery?: RequestQueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      key => 'undefined' !== typeof query[key],
    );
    return keys.length
      ? `?${keys
          .map(key =>
            typeof query[key] === 'object' && !Array.isArray(query[key])
              ? this.addQueryParams(query[key] as object).substring(1)
              : this.addQueryParam(query, key),
          )
          .join('&')}`
      : '';
  }

  private bodyFormatters: Record<BodyType, (input: any) => any> = {
    [BodyType.Json]: JSON.stringify,
    [BodyType.FormData]: (input: any) =>
      Object.keys(input).reduce((data, key) => {
        data.append(key, input[key]);
        return data;
      }, new FormData()),
  };

  private mergeRequestOptions(
    params: RequestParams,
    securityParams?: RequestParams,
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params,
      ...(securityParams || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params.headers || {}),
        ...((securityParams && securityParams.headers) || {}),
      },
    };
  }

  private safeParseResponse = <T = any, E = any>(
    response: Response,
  ): Promise<HttpResponse<T, E>> => {
    const r = response as HttpResponse<T, E>;
    r.data = null;
    r.error = null;

    return response
      .json()
      .then(data => {
        if (r.ok) {
          r.data = data;
        } else {
          r.error = data;
        }
        return r;
      })
      .catch(e => {
        r.error = e;
        return r;
      });
  };

  public request = <T = any, E = any>(
    path: string,
    method: string,
    { secure, ...params }: RequestParams = {},
    body?: any,
    bodyType?: BodyType,
    secureByDefault?: boolean,
  ): Promise<HttpResponse<T>> => {
    const requestUrl = `${this.baseUrl}${path}`;
    const secureOptions =
      (secureByDefault || secure) && this.securityWorker
        ? this.securityWorker(this.securityData)
        : {};
    const requestOptions = {
      ...this.mergeRequestOptions(params, secureOptions),
      method,
      body: body ? this.bodyFormatters[bodyType || BodyType.Json](body) : null,
    };

    return fetch(requestUrl, requestOptions).then(async response => {
      const data = await this.safeParseResponse<T, E>(response);
      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Документашка по API Аюсс-Встк
 * @version 1.0.0
 * Тут все, все, все есть
 */
export class Api<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  v1Api = {
    /**
     * @tags Адреса
     * @name addressesUpdate
     * @request PUT:/v1_api/addresses
     * @description Добавить новый адрес
     */
    addressesUpdate: (data: Address, params?: RequestParams) =>
      this.request<Address, any>(`/v1_api/addresses`, 'PUT', params, data),

    /**
     * @tags Cart
     * @name cartList
     * @request GET:/v1_api/cart
     * @description Получить корзину залогиненного пользователя
     */
    cartList: (params?: RequestParams) =>
      this.request<Cart, any>(`/v1_api/cart`, 'GET', params),

    /**
     * @tags Cart
     * @name cartDelete
     * @request DELETE:/v1_api/cart
     * @description Отчистить корзину
     */
    cartDelete: (params?: RequestParams) =>
      this.request<any, any>(`/v1_api/cart`, 'DELETE', params),

    /**
     * @tags Cart
     * @name cartElementsUpdate
     * @request PUT:/v1_api/cart/elements/{productId}
     * @description Добавить/изменить CartElement
     */
    cartElementsUpdate: (
      productId: string,
      data: { quantity?: number; price?: number },
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/v1_api/cart/elements/${productId}`,
        'PUT',
        params,
        data,
      ),

    /**
     * @tags Cart
     * @name cartElementsDelete
     * @request DELETE:/v1_api/cart/elements/{productId}
     * @description Удалить CartElement
     */
    cartElementsDelete: (productId: string, params?: RequestParams) =>
      this.request<any, any>(
        `/v1_api/cart/elements/${productId}`,
        'DELETE',
        params,
      ),

    /**
     * @tags Cart
     * @name cartOutletUpdate
     * @request PUT:/v1_api/cart/outlet
     * @description Установить торговую точку в корзину
     */
    cartOutletUpdate: (data: Outlet, params?: RequestParams) =>
      this.request<any, any>(`/v1_api/cart/outlet`, 'PUT', params, data),

    /**
     * @tags Cart
     * @name cartRefreshOffersUpdate
     * @request PUT:/v1_api/cart/refresh-offers
     * @description Обновить корзину данными из 1С
     */
    cartRefreshOffersUpdate: (params?: RequestParams) =>
      this.request<Cart, any>(`/v1_api/cart/refresh-offers`, 'PUT', params),

    /**
     * @tags Cart
     * @name cartCreateOrderUpdate
     * @request PUT:/v1_api/cart/create-order
     * @description Сделать заказ из данных в корзине
     */
    cartCreateOrderUpdate: (params?: RequestParams) =>
      this.request<Order, any>(`/v1_api/cart/create-order`, 'PUT', params),

    /**
     * @tags Категорий
     * @name categoriesList
     * @request GET:/v1_api/categories
     * @description Получить все категории
     */
    categoriesList: (params?: RequestParams) =>
      this.request<Category[], any>(`/v1_api/categories`, 'GET', params),

    /**
     * @tags Контрагенты
     * @name contractorsList
     * @request GET:/v1_api/contractors
     * @description Получить контрагентов
     */
    contractorsList: (
      query?: { offset?: string; limit?: string; query?: string },
      params?: RequestParams,
    ) =>
      this.request<{ totalCount?: number; data?: Contractor[] }, any>(
        `/v1_api_private/contractors${this.addQueryParams(query)}`,
        'GET',
        params,
      ),

    /**
     * @tags Контрагенты
     * @name contractorsDetail
     * @request GET:/v1_api/contractors/{id}
     * @description Получить контрагента
     */
    contractorsDetail: (id: string, params?: RequestParams) =>
      this.request<Contractor, any>(
        `/v1_api_private/contractors/${id}`,
        'GET',
        params,
      ),

    /**
     * @tags Контрагенты
     * @name contractorsUsersCreate
     * @request POST:/v1_api/contractors/{id}/users
     * @description Добавление пользователя в контрагента
     */
    contractorsUsersCreate: (id: string, data: User, params?: RequestParams) =>
      this.request<any, any>(
        `/v1_api_private/contractors/${id}/users`,
        'POST',
        params,
        data,
      ),

    /**
     * @tags Заказы
     * @name ordersList
     * @request GET:/v1_api/orders
     * @description Получить заказы текущего пользователя
     */
    ordersList: (query: any, params?: RequestParams) =>
      this.request<{ totalCount?: number; data?: OrderWithMeta[] }, any>(
        `/v1_api_private/orders${this.addQueryParams(query)}`,
        'GET',
        params,
      ),

    /**
     * @tags Заказы
     * @name ordersDetail
     * @request GET:/v1_api/orders/{id}
     * @description Получить заказы текущего пользователя
     */
    ordersDetail: (id: string | number, params?: RequestParams) =>
      this.request<Order, any>(`/v1_api_private/orders/${id}`, 'GET', params),

    /**
     * @tags Торговые точки
     * @name outletsDetail
     * @request GET:/v1_api/outlets/{id}
     * @description Получить торговую точку по id
     */
    outletsDetail: (id: string, params?: RequestParams) =>
      this.request<Outlet, any>(`/v1_api/outlets/${id}`, 'GET', params),

    /**
     * @tags Product
     * @name productsDetail
     * @request GET:/v1_api/products/{id}
     * @description Получить Product по id
     */
    productsDetail: (id: int, params?: RequestParams) =>
      this.request<Product, any>(`/v1_api/products/${id}`, 'GET', params),

    /**
     * @tags Product
     * @name productsList
     * @request GET:/v1_api/products
     * @description Получить Product списком
     */
    productsList: (
      query?: {
        offset?: string | int | null;
        limit?: string | int | null;
        categories?: string | int | null;
        brands?: string | int | null;
        manufacturers?: string | int | null;
        query?: string | null;
        sort?: string | null;
      },
      params?: RequestParams,
    ) =>
      this.request<{ totalCount?: number; data?: Product[] }, any>(
        `/v1_api/products${this.addQueryParams(query)}`,
        'GET',
        params,
      ),

    /**
     * @tags Product
     * @name facetsList
     * @request GET:/v1_api/facets
     * @description Получить фасеты
     */
    facetsList: (
      query?: {
        categories?: int;
        brands?: int;
        manufacturers?: int;
        query?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/v1_api/facets${this.addQueryParams(query)}`,
        'GET',
        params,
      ),

    /**
     * @tags Аутентификация
     * @name loginCreate
     * @request POST:/v1_api/login
     * @description Залогинеть пользователя
     */
    loginCreate: (
      data: { login?: string; password?: string },
      params?: RequestParams,
    ) => this.request<any, any>(`/v1_api/login`, 'POST', params, data),

    /**
     * @tags Аутентификация
     * @name loginDelete
     * @request DELETE:/v1_api/login
     * @description Разлогиниться
     */
    loginDelete: (params?: RequestParams) =>
      this.request<any, any>(`/v1_api/login`, 'DELETE', params),

    /**
     * @tags Пользователи
     * @name usersList
     * @request GET:/v1_api/users
     * @description Получить всех пользователей
     */
    usersList: (
      query?: { offset?: string; limit?: string; query?: string },
      params?: RequestParams,
    ) =>
      this.request<{ totalCount?: number; data?: User[] }, any>(
        `/v1_api/users${this.addQueryParams(query)}`,
        'GET',
        params,
      ),

    /**
     * @tags Пользователи
     * @name usersMeList
     * @request GET:/v1_api/users/me
     * @description Получить текущего пользователя
     */
    usersMeList: (params?: RequestParams) =>
      this.request<User, any>(`/v1_api/users/me`, 'GET', params),

    /**
     * @tags Склады
     * @name warehousesList
     * @request GET:/v1_api/warehouses
     * @description Получить склады
     */
    warehousesList: (
      query?: { offset?: string; limit?: string },
      params?: RequestParams,
    ) =>
      this.request<{ totalCount?: number; data?: Warehouse[] }, any>(
        `/v1_api/warehouses${this.addQueryParams(query)}`,
        'GET',
        params,
      ),

    /**
     * @tags Склады
     * @name warehousesDetail
     * @request GET:/v1_api/warehouses/{id}
     * @description Получить склад по id
     */
    warehousesDetail: (id: string, params?: RequestParams) =>
      this.request<Warehouse, any>(`/v1_api/warehouses/${id}`, 'GET', params),

    /**
     * @tags Склады
     * @name warehousesAddressUpdate
     * @request PUT:/v1_api/warehouses/{id}/address
     * @description Изменение адреса склада, нужна уже существующая сущность
     */
    warehousesAddressUpdate: (
      id: string,
      data: Address,
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/v1_api/warehouses/${id}/address`,
        'PUT',
        params,
        data,
      ),
  };
  v1ApiPrivate = {
    /**
     * @tags Пользователи
     * @name usersCreate
     * @request POST:/v1_api_private/users
     * @description Создать юзера
     */
    usersCreate: (data: User, params?: RequestParams) =>
      this.request<User, any>(`/v1_api_private/users`, 'POST', params, data),
    user: (id: string | number, params?: RequestParams) =>
      this.request<{ data: User }, any>(
        `/v1_api_private/users/${id}`,
        'GET',
        params,
      ),
    promotionsList: (
      query?: { offset?: string; limit?: string; outlets?: string },
      params?: RequestParams,
    ) =>
      this.request<{ totalCount?: number; data?: Promotion[] }, any>(
        `/v1_api_private/promotions${this.addQueryParams(query)}`,
        'GET',
        params,
      ),

    promotionInitiatorsList: (
      query?: { offset?: string; limit?: string },
      params?: RequestParams,
    ) =>
      this.request<{ totalCount?: number; data?: PromotionInitiator[] }, any>(
        `/v1_api_private/promotion-initiators${this.addQueryParams(query)}`,
        'GET',
        params,
      ),

    promotionsDetail: (id: string, params?: RequestParams) =>
      this.request<Promotion, any>(
        `/v1_api_private/promotions/${id}`,
        'GET',
        params,
      ),

    promotionsInitiatorDetail: (id: string, params?: RequestParams) =>
      this.request<PromotionInitiator, any>(
        `/v1_api_private/promotion-initiators/${id}`,
        'GET',
        params,
      ),

    promotionsUpdate: (
      id: string | number,
      data: Partial<Promotion>,
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/v1_api_private/promotions/${id}`,
        'PATCH',
        params,
        data,
      ),

    promotionsInitiatorsUpdate: (
      id: string | number,
      data: Partial<PromotionInitiator>,
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/v1_api_private/promotion-initiators/${id}`,
        'PATCH',
        params,
        data,
      ),

    promotionsUploadBanner: (
      id: string | number,
      data: any,
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/v1_api_private/promotions/${id}/banner`,
        'POST',
        params,
        data,
        BodyType.FormData,
      ),

    promotionsUploadWobbler: (
      id: string | number,
      data: any,
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/v1_api_private/promotions/${id}/wobbler`,
        'POST',
        params,
        data,
        BodyType.FormData,
      ),

    promotionsBannerDelete: (id: string | number, params?: RequestParams) =>
      this.request<any, any>(
        `/v1_api_private/promotions/${id}/banner`,
        'DELETE',
        params,
      ),

    promotionsWobblerDelete: (id: string | number, params?: RequestParams) =>
      this.request<any, any>(
        `/v1_api_private/promotions/${id}/wobbler`,
        'DELETE',
        params,
      ),

    promotionsInitiatorsUploadBanner: (
      id: string | number,
      data: any,
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/v1_api_private/promotion-initiators/${id}/banner`,
        'POST',
        params,
        data,
        BodyType.FormData,
      ),

    promotionsInitiatorsUploadWobbler: (
      id: string | number,
      data: any,
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/v1_api_private/promotion-initiators/${id}/wobbler`,
        'POST',
        params,
        data,
        BodyType.FormData,
      ),

    promotionsInitiatorsBannerDelete: (
      id: string | number,
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/v1_api_private/promotion-initiators/${id}/banner`,
        'DELETE',
        params,
      ),

    promotionsInitiatorsWobblerDelete: (
      id: string | number,
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/v1_api_private/promotion-initiators/${id}/wobbler`,
        'DELETE',
        params,
      ),

    zakazCreate: (
      id: string | number,
      data: any = {},
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/v1_api_private/orders/${id}/make-zakaz`,
        'POST',
        params,
        data,
      ),
    realizationCreate: (
      guid: string | number,
      data: any = {},
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/v1_api_private/zakazes/${guid}/make-realization`,
        'POST',
        params,
        data,
      ),

    orderLogs: (id: string | number, params?: RequestParams) =>
      this.request<{ data: OrderLog[] }, any>(
        `/v1_api_private/orders/${id}/logs`,
        'GET',
        params,
      ),

    zakazLogs: (guid: string | number, params?: RequestParams) =>
      this.request<{ data: ZakazLog[] }, any>(
        `/v1_api_private/zakazes/${guid}/logs`,
        'GET',
        params,
      ),
  };
}
