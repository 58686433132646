import React, { FC } from 'react';
import cn from 'clsx';
import { Button } from '@mui/material';

interface Props {
  json: any;
  title: string;
  className?: string | null;
  disabled?: boolean;
  count?: number | null;
}

export const downloadXlsx= (json: any) => {
  fetch(`${process.env.REACT_APP_HELPER_HOST}/json-to-table?xlsx`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(json),
  })
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${new Date().toISOString()}.xlsx`;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    });
};

const DownloadXlsx: FC<Props> = ({
  json,
  title,
  className,
  disabled,
  count,
}) => {

  const handleOnClick = () => downloadXlsx(json)

  return (
    <Button
      disabled={disabled}
      className={cn(
        disabled ? 'opacity-50' : 'hover:text-theme-primary-700',
        className,
      )}
      onClick={handleOnClick}
    >
      {title} {Boolean(count) && `(${count})`}
    </Button>
  );
};

export default DownloadXlsx;
