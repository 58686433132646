import React, { useMemo, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Paper,
  Theme,
  Link,
  Button,
  Tabs,
  Tab,
  Icon,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Grid,
  Chip,
} from '@mui/material';
import {
  CommentOutlined,
  LocationOnOutlined,
  Warning,
} from '@mui/icons-material';
import {
  apiService,
  useGetOrder,
  useGetOrderLogs,
  useGetZakazesLogs,
} from 'src/services/apiService';
import { useRouter } from 'src/hooks/useRouter';
import { EntityTitle } from '../EntityTitle';
import { format } from 'date-fns';
import { Table } from 'src/components/common/Table';
import {
  Order,
  OrderLine,
  OrderState,
  Realization,
  Zakaz,
  ZakazLine,
} from 'src/types/api';
import { PorductPreview } from 'src/components/PorductPreview';
import { formatPrice } from 'src/helpers/formatting';
import Color from 'color';
import { green, red } from '@material-ui/core/colors';
import { WithLoading } from 'src/components/common/WithLoading';
import { StockItemPrice } from '../StockItemPrice';
import { routesMap } from 'src/routes';
import ReactJson from 'react-json-view';

import { MakeReserveButton } from 'src/features/make-reserve-button';
import {
  PrintRealizationDocumens,
  PrintZakazesDocumens,
} from 'src/features/print-order-documens';
import { WithConfirm } from 'src/shared/ui';
import { deleteOrderRequest } from 'src/entities/order';

const compareItemsPrices = (
  documentItem: ZakazLine,
  orderItems?: OrderLine[],
) => {
  const orderItem = orderItems?.find(
    item => item.product?.code === documentItem.product?.code,
  );

  return orderItem &&
    documentItem?.offerPrice &&
    orderItem?.singlePrice &&
    documentItem.quantity
    ? documentItem?.offerPrice * documentItem.quantity -
        orderItem?.singlePrice * documentItem.quantity
    : null;
};

const documentsTotalCost = (documents: any) =>
  documents?.reduce((prev: any, curr: any) => prev + curr?.totalCost || 0, 0);

const compareDocumentsPrices = (documents: any, orderTotalCost?: number) => {
  const documentsCost = documentsTotalCost(documents);

  return orderTotalCost && documentsCost ? documentsCost - orderTotalCost : 0;
};

const countProductInDocument = (lines: any) =>
  lines?.reduce(
    (prev: any, curr: any) => ({
      ...prev,
      [curr?.product?.code]: (prev[curr?.product?.code] || 0) + curr.quantity,
    }),
    {},
  );

interface ZakazesProps {
  order?: Order | null;
  onCreateZakaz: () => any;
  onCreateRealization: () => any;
  onMakeReserve: () => any;
  isLoading: boolean;
}

const Zakazes: React.FunctionComponent<ZakazesProps> = ({
  order,
  onCreateZakaz,
  onCreateRealization,
  onMakeReserve,
  isLoading,
}) => {
  const classes = useStyles({});
  const router = useRouter();

  const [loadingCreateZakaz, setLoadingCreateZakaz] = useState(false);

  const orderLogsRequest = useGetOrderLogs({
    variables: { id: router?.query?.orderId },
  });

  const orderProductsCount = useMemo(
    () => countProductInDocument(order?.lines),
    [order],
  );

  const zakazesProducts = useMemo(
    () => order?.zakazes?.map(zakaz => zakaz?.lines)?.flat(),
    [order],
  );

  const zakazesProductsCount = useMemo(
    () => countProductInDocument(zakazesProducts),
    [zakazesProducts],
  );

  if (!order) return null;

  const handleCreateZakaz = () => {
    if (order?.id) {
      setLoadingCreateZakaz(true);
      apiService.v1ApiPrivate.zakazCreate(order?.id).then(() => {
        onCreateZakaz();
        setLoadingCreateZakaz(false);
      });
    }
  };

  const handleCreateRealization = (quid: string) => {
    apiService.v1ApiPrivate
      .realizationCreate(quid)
      .then(() => onCreateRealization());
  };

  return (
    <>
      <Box mt={2}>
        <Paper>
          <Box p={2}>
            <Button
              disabled={
                ![OrderState.Draft, OrderState.PendingReview].includes(
                  order.state,
                ) || loadingCreateZakaz
              }
              variant="contained"
              color="primary"
              onClick={handleCreateZakaz}
            >
              Создать заказ(ы)
              {order.lastRequest &&
                ` (${format(
                  new Date(order.lastRequest),
                  'yyyy-MM-dd HH:mm:ss',
                )})`}
            </Button>
          </Box>
        </Paper>
      </Box>

      {Boolean(orderLogsRequest.data?.data?.length) && (
        <Box mt={2}>
          <Accordion>
            <AccordionSummary style={{ backgroundColor: '#f500578c' }}>
              Ошибки
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {orderLogsRequest.data &&
                  orderLogsRequest.data?.data?.map((item, index) => (
                    <Box key={item.id} mt={2}>
                      <ReactJson
                        enableClipboard={false}
                        name={`${index}`}
                        src={{
                          ...item,
                          response: (() => {
                            try {
                              return JSON.parse(item.response);
                            } catch (e) {
                              return item.response;
                            }
                          })(),
                        }}
                        theme="ocean"
                        style={{ backgroundColor: 'unset' }}
                      />
                    </Box>
                  ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      {order?.zakazes?.map(zakaz => (
        <Box mt={2} key={zakaz.idInternal}>
          <Paper>
            <Box p={2} display="flex">
              {(!zakaz.realizationDocument ||
                (!zakaz.realizationDocument && !zakaz.reserveIdInternal)) && (
                <>
                  {!zakaz.realizationDocument && (
                    <Box mr={2}>
                      <Button
                        disabled={Boolean(zakaz.lastRequest)}
                        variant="contained"
                        color="primary"
                        onClick={() => handleCreateRealization(zakaz.guid)}
                      >
                        Создать реализацию
                        {zakaz.lastRequest &&
                          ` ${format(
                            new Date(zakaz.lastRequest),
                            'yyyy-MM-dd HH:mm:ss',
                          )}`}
                      </Button>
                    </Box>
                  )}
                  {!zakaz.realizationDocument && !zakaz.reserveIdInternal && (
                    <Box mr={2}>
                      <MakeReserveButton
                        onCreate={onMakeReserve}
                        zakazGuid={zakaz.guid}
                        zakazIdInternal={zakaz.idInternal}
                      />
                    </Box>
                  )}
                </>
              )}
              <PrintZakazesDocumens guid={zakaz.guid} />
            </Box>
            <Table
              title={`${zakaz.idInternal}, ${zakaz.guid}${
                zakaz.reserveIdInternal
                  ? `, Резерв ${zakaz.reserveIdInternal}`
                  : ''
              }`}
              rowStyle={(item: ZakazLine) =>
                item?.quantity === 0
                  ? {
                      backgroundColor: Color(red[500]).alpha(0.1).string(),
                    }
                  : null
              }
              dense={true}
              data={zakaz?.lines || []}
              loading={isLoading}
              withSummary
              headers={[
                {
                  title: 'Товар',
                  extract: (item: ZakazLine) => (
                    <PorductPreview product={item?.product} />
                  ),
                },
                {
                  title: 'Количество',
                  align: 'right',
                  extract: (item: ZakazLine) => (
                    <Box>
                      <Box>{item?.quantity}</Box>
                      {zakazesProductsCount[item?.product?.code as number] <
                      orderProductsCount[item?.product?.code as number] ? (
                        <Box>
                          {zakazesProductsCount[item?.product?.code as number]}/
                          {orderProductsCount[item?.product?.code as number]}
                        </Box>
                      ) : null}
                    </Box>
                  ),
                },
                {
                  summary: (
                    <div className={classes.noWrap}>
                      {zakaz?.totalCost && formatPrice(zakaz?.totalCost / 100)}{' '}
                      ₽
                    </div>
                  ),
                  title: 'Цена',
                  align: 'right',
                  extract: (item: ZakazLine) => (
                    <StockItemPrice
                      offerPrice={item?.totalCost}
                      diff={compareItemsPrices(item, order?.lines)}
                      singlePrice={item?.offerPrice}
                    />
                  ),
                },
              ]}
            />
          </Paper>
        </Box>
      ))}
      {Boolean(zakazesProducts?.length) &&
        order?.lines?.some(
          orderLine =>
            !zakazesProducts?.find(
              zakazLine => zakazLine?.product?.code === orderLine.product?.code,
            ),
        ) && (
          <Box mt={2}>
            <Paper
              sx={{
                backgroundColor: Color(red[500]).alpha(0.1).string(),
              }}
            >
              <Table
                title="Не оказалось в наличии"
                dense={true}
                data={
                  order?.lines?.filter(
                    orderLine =>
                      !zakazesProducts?.find(
                        zakazLine =>
                          zakazLine?.product?.code === orderLine.product?.code,
                      ),
                  ) || []
                }
                loading={isLoading}
                withSummary
                headers={[
                  {
                    title: 'Товар',
                    extract: (item: ZakazLine) => (
                      <PorductPreview product={item?.product} />
                    ),
                  },
                  {
                    title: 'Количество',
                    align: 'right',
                    extract: (item: ZakazLine) => <>{item?.quantity}</>,
                  },
                  {
                    title: 'Цена',
                    align: 'right',
                    extract: (item: ZakazLine) => (
                      <StockItemPrice
                        offerPrice={item?.totalCost}
                        diff={compareItemsPrices(item, order?.lines)}
                        singlePrice={item?.offerPrice}
                      />
                    ),
                  },
                ]}
              />
            </Paper>
          </Box>
        )}
    </>
  );
};

interface Props {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      noWrap: {
        whiteSpace: 'nowrap',
      },
      singlePrice: {
        fontSize: theme.typography.pxToRem(11),
      },
      muted: {
        opacity: 0.5,
      },
      inlineIcon: {
        width: '16px',
        height: '16px',
      },
    }),
  { name: 'OrderPage' },
);

export const OrderPage: React.FunctionComponent<Props> = ({}) => {
  const classes = useStyles({});
  const router = useRouter();
  const [tab, setTab] = useState<string>('order');

  const orderRequest = useGetOrder({
    variables: { id: router?.query?.orderId },
  });

  const order = orderRequest?.data;

  const realizations = orderRequest?.data?.zakazes
    ?.map(zakaz => zakaz.realizationDocument as Realization)
    .filter(item => item);

  const orderLogsRequest = useGetOrderLogs({
    variables: { id: router?.query?.orderId },
  });

  const zakazesLogsRequest = useGetZakazesLogs({
    variables: { guids: order?.zakazes?.map(zakaz => zakaz.guid).join(',') },
    skip: !order?.zakazes?.length,
  });

  const handleDeleteOrder = () => {
    if (order?.id) {
      deleteOrderRequest(order.id).then(() => orderRequest.refetch());
    }
  };

  const orderProductsCount = useMemo(
    () => countProductInDocument(order?.lines),
    [order],
  );

  const realizationProducts = useMemo(
    () =>
      order?.zakazes
        ?.map(zakaz => zakaz?.realizationDocument?.items)
        ?.flat()
        ?.filter(item => item),
    [order],
  );

  const realizationsProductsCount = useMemo(
    () => countProductInDocument(realizationProducts),

    [realizationProducts],
  );

  return (
    <WithLoading loading={orderRequest?.isLoading}>
      <div className={classes.root}>
        <EntityTitle
          id={order?.id}
          title={`Заказ от ${
            order?.createdAt &&
            format(new Date(order?.createdAt), 'yyyy-MM-dd HH:mm:ss')
          } обновлен ${
            order?.updatedAt &&
            format(new Date(order?.updatedAt), 'yyyy-MM-dd HH:mm:ss')
          }`}
        />
        <Box>
          <Paper>
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box p={2} fontSize="16px" mt={2}>
                    <Box>
                      {[
                        order?.creator?.id && (
                          <Link
                            href={routesMap?.user?.getRoute(order?.creator?.id)}
                            target="_blank"
                          >
                            {order?.creator?.lastName}{' '}
                            {order?.creator?.firstName}{' '}
                            {order?.creator?.fatherName}
                          </Link>
                        ),
                        order?.creator?.phoneNumber && (
                          <Link href={`tel:+7${order?.creator?.phoneNumber}`}>
                            +7{order?.creator?.phoneNumber}
                          </Link>
                        ),
                      ]
                        ?.filter(item => item)
                        .map((item, i) => (
                          <span key={i}>{item} </span>
                        ))}
                    </Box>
                    <Box mt={2}>
                      {[
                        order?.outlet?.contractor?.id && (
                          <Link
                            href={routesMap?.contractor?.getRoute(
                              order?.outlet?.contractor?.id,
                            )}
                            target="_blank"
                          >
                            {order?.outlet?.contractor?.name}
                          </Link>
                        ),
                        order?.outlet?.contractor?.inn,
                        order?.outlet?.contractor?.vendorId && (
                          <span className={classes.muted}>
                            [{order?.outlet?.contractor?.vendorId}]
                          </span>
                        ),
                      ]
                        ?.filter(item => item)
                        .map((item, i) => (
                          <span key={i}>{item} </span>
                        ))}
                    </Box>
                    <Box mt={2}>
                      {[
                        order?.outlet?.address?.line,
                        <Link
                          target="_blank"
                          href={`https://yandex.ru/maps?mode=whatshere&whatshere%5Bpoint%5D=${order?.outlet?.address?.longitude}%2C${order?.outlet?.address?.latitude}&whatshere%5Bzoom%5D=15&z=17`}
                        >
                          <LocationOnOutlined className={classes.inlineIcon} />
                        </Link>,
                        order?.outlet?.vendorId && (
                          <span className={classes.muted}>
                            [{order?.outlet?.vendorId}]
                          </span>
                        ),
                      ]
                        ?.filter(item => item)
                        .map((item, i) => (
                          <span key={i}>{item} </span>
                        ))}
                    </Box>
                    {order?.comment && (
                      <Box display="flex" alignItems="celner" mt={2}>
                        <CommentOutlined /> <Box ml={2}>{order?.comment}</Box>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Chip title="Статус"  label={order?.state} size="medium" />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <List dense>
                    <ListItem divider>
                      <ListItemIcon>
                        <Checkbox checked={!order?.isPickup} disabled />
                      </ListItemIcon>
                      <ListItemText primary="доставка" />
                    </ListItem>
                    <ListItem divider>
                      <ListItemIcon>
                        <Checkbox checked={order?.payOnReceive?.selected} />
                      </ListItemIcon>
                      <ListItemText primary="оплата при получении" />
                    </ListItem>
                    <ListItem divider>
                      <ListItemText
                        inset
                        primary={
                          order?.paymentMethod?.values?.[
                            order?.paymentMethod?.selected
                          ]
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        inset
                        primary={
                          order?.paymentKind?.values?.[
                            order?.paymentKind?.selected
                          ]
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
              {order?.state === OrderState.PendingReview && (
                <Box display="flex">
                  <WithConfirm
                    confirmTitle="Удалить"
                    onConfirm={handleDeleteOrder}
                  >
                    <Button color="error" variant="outlined">
                      Удалить
                    </Button>
                  </WithConfirm>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
        <Box mt={2}>
          <Paper>
            <Tabs onChange={(e, value) => setTab(value)} value={tab}>
              <Tab
                value="order"
                label={
                  <Box display="flex" alignItems="center">
                    Заказ клиента{' '}
                    {order?.totalCost && (
                      <> {formatPrice(order?.totalCost / 100)}&nbsp;₽</>
                    )}
                  </Box>
                }
              />
              <Tab
                value="zakazes"
                label={
                  <Box display="flex" alignItems="center">
                    {`Заказы упп (${order?.zakazes?.length || 0}) `}
                    {compareDocumentsPrices(
                      order?.zakazes,
                      order?.totalCost,
                    ) !== 0 && (
                      <Box
                        ml={1}
                        style={{
                          color:
                            compareDocumentsPrices(
                              order?.zakazes,
                              order?.totalCost,
                            ) === 0
                              ? 'unset'
                              : compareDocumentsPrices(
                                  order?.zakazes,
                                  order?.totalCost,
                                ) > 0
                              ? red[500]
                              : green[500],
                        }}
                        title={`${formatPrice(
                          documentsTotalCost(order?.zakazes) / 100,
                        )} ₽`}
                      >
                        {formatPrice(
                          compareDocumentsPrices(
                            order?.zakazes,
                            order?.totalCost,
                          ) / 100,
                        )}
                        &nbsp;₽
                      </Box>
                    )}
                    {Boolean(orderLogsRequest.data?.data?.length) && (
                      <Box ml={1}>
                        <Icon color="secondary">
                          <Warning />
                        </Icon>
                      </Box>
                    )}
                  </Box>
                }
              />
              <Tab
                value="realizations"
                label={
                  <Box display="flex" alignItems="center">
                    {`Реализации (${realizations?.length || 0})`}
                    {compareDocumentsPrices(
                      order?.zakazes?.map(zakaz => zakaz.realizationDocument),
                      order?.totalCost,
                    ) !== 0 && (
                      <Box
                        ml={1}
                        style={{
                          color:
                            compareDocumentsPrices(
                              order?.zakazes?.map(
                                zakaz => zakaz.realizationDocument,
                              ),
                              order?.totalCost,
                            ) === 0
                              ? 'unset'
                              : compareDocumentsPrices(
                                  order?.zakazes?.map(
                                    zakaz => zakaz.realizationDocument,
                                  ),
                                  order?.totalCost,
                                ) > 0
                              ? red[500]
                              : green[500],
                        }}
                        title={`${formatPrice(
                          documentsTotalCost(
                            order?.zakazes?.map(
                              zakaz => zakaz.realizationDocument,
                            ),
                          ) / 100,
                        )} ₽`}
                      >
                        {formatPrice(
                          compareDocumentsPrices(
                            order?.zakazes?.map(
                              zakaz => zakaz.realizationDocument,
                            ),
                            order?.totalCost,
                          ) / 100,
                        )}
                        &nbsp;₽
                      </Box>
                    )}
                    {Boolean(
                      zakazesLogsRequest.data &&
                        Object.values(zakazesLogsRequest.data).length,
                    ) && (
                      <Box ml={2}>
                        <Icon color="secondary">
                          <Warning />
                        </Icon>
                      </Box>
                    )}
                  </Box>
                }
              />
            </Tabs>
          </Paper>
        </Box>
        {tab === 'order' && order && (
          <Box mt={2}>
            <Paper>
              <Table
                title="Заказ клиента"
                rowStyle={(item: OrderLine) =>
                  item?.quantity === 0
                    ? {
                        backgroundColor: Color(red[500]).alpha(0.1).string(),
                      }
                    : null
                }
                dense={true}
                data={order?.lines || []}
                loading={orderRequest?.isLoading}
                withSummary
                headers={[
                  {
                    title: 'Товар',
                    extract: (item: OrderLine) => (
                      <PorductPreview product={item?.product} />
                    ),
                  },
                  {
                    title: 'Количество',
                    align: 'right',
                    extract: (item: OrderLine) => <>{item?.quantity}</>,
                  },
                  {
                    summary: (
                      <div className={classes.noWrap}>
                        {order?.totalCost &&
                          formatPrice(order?.totalCost / 100)}{' '}
                        ₽
                      </div>
                    ),
                    title: 'Цена',
                    align: 'right',
                    extract: (item: OrderLine) => (
                      <StockItemPrice
                        offerPrice={item?.totalCost}
                        singlePrice={item?.singlePriceInKopeck}
                      />
                    ),
                  },
                ]}
              />
            </Paper>
          </Box>
        )}

        {tab === 'zakazes' && (
          <Zakazes
            order={order}
            isLoading={orderRequest.isLoading}
            onCreateZakaz={orderRequest.refetch}
            onCreateRealization={orderRequest.refetch}
            onMakeReserve={orderRequest.refetch}
          />
        )}
        {tab === 'realizations' && (
          <>
            {zakazesLogsRequest.data &&
              Boolean(Object.values(zakazesLogsRequest.data)?.length) && (
                <Box mt={2}>
                  <Accordion>
                    <AccordionSummary style={{ backgroundColor: '#f500578c' }}>
                      Ошибки
                    </AccordionSummary>

                    <AccordionDetails>
                      <Box>
                        {zakazesLogsRequest.data &&
                          Object.entries(zakazesLogsRequest.data)?.map(
                            (item, index) => (
                              <Box key={item[0]} mt={2}>
                                {item[1] && (
                                  <ReactJson
                                    enableClipboard={false}
                                    name={`${index}`}
                                    src={item[1]?.map(item => ({
                                      ...item,
                                      response: (() => {
                                        try {
                                          return JSON.parse(item.response);
                                        } catch (e) {
                                          return item.response;
                                        }
                                      })(),
                                    }))}
                                    theme="ocean"
                                    style={{ backgroundColor: 'unset' }}
                                  />
                                )}
                              </Box>
                            ),
                          )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}
            {realizations?.map(realization => (
              <Box mt={2} key={realization.idInternal}>
                <Paper>
                  <Box p={2} display="flex">
                    {realization.guid && (
                      <PrintRealizationDocumens guid={realization.guid} />
                    )}
                  </Box>
                  <Table
                    title={`${realization.idInternal}, ${realization.guid}`}
                    rowStyle={(item: OrderLine) =>
                      item?.quantity === 0
                        ? {
                            backgroundColor: Color(red[500])
                              .alpha(0.1)
                              .string(),
                          }
                        : null
                    }
                    dense={true}
                    data={realization?.items || []}
                    loading={orderRequest?.isLoading}
                    withSummary
                    headers={[
                      {
                        title: 'Товар',
                        extract: (item: OrderLine) => (
                          <PorductPreview product={item?.product} />
                        ),
                      },
                      {
                        title: 'Количество',
                        align: 'right',
                        extract: (item: OrderLine) => (
                          <Box>
                            <Box>{item?.quantity}</Box>
                            {realizationsProductsCount[
                              item?.product?.code as number
                            ] <
                            orderProductsCount[
                              item?.product?.code as number
                            ] ? (
                              <Box sx={{ color: red[500], fontWeight: 900 }}>
                                {
                                  realizationsProductsCount[
                                    item?.product?.code as number
                                  ]
                                }
                                /
                                {
                                  orderProductsCount[
                                    item?.product?.code as number
                                  ]
                                }
                              </Box>
                            ) : null}
                          </Box>
                        ),
                      },
                      {
                        summary: (
                          <div className={classes.noWrap}>
                            {realization?.totalCost &&
                              formatPrice(realization?.totalCost / 100)}{' '}
                            ₽
                          </div>
                        ),
                        title: 'Цена',
                        align: 'right',
                        extract: (item: OrderLine) => (
                          <StockItemPrice
                            offerPrice={item?.totalCost}
                            diff={compareItemsPrices(item, order?.lines)}
                            singlePrice={item?.offerPrice}
                          />
                        ),
                      },
                    ]}
                  />
                </Paper>
              </Box>
            ))}
            {Boolean(realizationProducts?.length) &&
              order?.lines?.some(
                orderLine =>
                  !realizationProducts?.find(
                    realizationLine =>
                      realizationLine?.product?.code ===
                      orderLine.product?.code,
                  ),
              ) && (
                <Box mt={2}>
                  <Paper
                    sx={{
                      backgroundColor: Color(red[500]).alpha(0.1).string(),
                    }}
                  >
                    <Table
                      title="Не оказалось в наличии"
                      dense={true}
                      data={
                        order?.lines?.filter(
                          orderLine =>
                            !realizationProducts?.find(
                              realizationLine =>
                                realizationLine?.product?.code ===
                                orderLine.product?.code,
                            ),
                        ) || []
                      }
                      loading={orderRequest?.isLoading}
                      withSummary
                      headers={[
                        {
                          title: 'Товар',
                          extract: (item: OrderLine) => (
                            <PorductPreview product={item?.product} />
                          ),
                        },
                        {
                          title: 'Количество',
                          align: 'right',
                          extract: (item: OrderLine) => <>{item?.quantity}</>,
                        },
                        {
                          title: 'Цена',
                          align: 'right',
                          extract: (item: OrderLine) => (
                            <StockItemPrice
                              offerPrice={item?.totalCost}
                              diff={compareItemsPrices(item, order?.lines)}
                              singlePrice={item?.singlePrice}
                            />
                          ),
                        },
                      ]}
                    />
                  </Paper>
                </Box>
              )}
          </>
        )}
      </div>
    </WithLoading>
  );
};
