import { useGetUserMe as depricated_useGetUserMe } from 'src/services/apiService';
import { useGetUser as depricated_useGetUser } from 'src/services/apiService';
import {
  RequestHookParams,
  ResponseWithMeta,
  User,
  request,
  useRequest,
} from 'src/shared/api';
import { RequestByLimitOffset } from 'src/shared/api/request';
import { apiRoutesMap } from 'src/shared/config';

export const useGetUserMe = depricated_useGetUserMe;

export const useGetUser = depricated_useGetUser;

export const changeUserRequest = (id: number | string, data: Partial<User>) =>
  request.patch(apiRoutesMap.user.getRoute(id), data);

export const useGetUsers = (
  params: RequestHookParams<{
    from?: string | null;
    to?: string;
    type?: string;
  } & RequestByLimitOffset>,
) =>
  useRequest<ResponseWithMeta<User[]>>(
    apiRoutesMap.users.getRoute(),
    params,
  );
