import React from 'react';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

interface Props {
  label?: string;
  value?: string | null;
  editable?: boolean | null;
  onChange: (value: string | null) => any;
  format?: string
  type?: 'dateTime' | 'date'
}

export const DateControl: React.FunctionComponent<Props> = ({
  label,
  value,
  onChange,
  editable,
  format: dateFormant = 'yyyy-MM-dd HH:mm:ss',
  type = 'dateTime'
}) => {
  const handleChange = (value: any) =>
    value && onChange(format(value, dateFormant));

  if(type === 'date') return  (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
    <DatePicker
      label={label}
      disabled={!editable}
      slotProps={{ textField: { fullWidth: true } }}
      //@ts-ignore
      value={value && new Date(value)}
      onChange={handleChange}
      format={dateFormant}
    />
  </LocalizationProvider>
  )
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <DateTimePicker
        label={label}
        disabled={!editable}
        slotProps={{ textField: { fullWidth: true } }}
        //@ts-ignore
        value={value && new Date(value)}
        onChange={handleChange}
        format={dateFormant}
      />
    </LocalizationProvider>
  );
};
