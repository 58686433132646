import { Button, Dialog, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import { EntityControl, Props } from '../../entity-control';

export const EntityCreate: React.FunctionComponent<
  Props & { labelButtonCreate?: string }
> = ({ item, config, labelButtonCreate }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = (data: any) => {
    if (config?.onChange)
      return config?.onChange({ ...item, ...data })?.then(handleClose);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <EntityControl
            item={item}
            config={{ ...config, onChange: handleSave }}
          />
        </DialogContent>
      </Dialog>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        {labelButtonCreate || 'Добавить'}
      </Button>
    </>
  );
};
