import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Button, IconButton, Theme } from '@mui/material';
import { Autocomplete } from 'src/components/common/Autocomplete';
import { Link } from '@mui/icons-material';
import { useDebounce } from 'src/hooks/useDebounce';
import { useGetUsers } from 'src/services/apiService';
import { routesMap } from 'src/routes';
import { User as IUser } from 'src/shared/api';
import { User } from 'src/entities/user';

interface Props {
  user?: IUser | null;
  onChange?: (user: IUser | null) => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'UserSelect' },
);

export const UserSelect: React.FunctionComponent<Props> = ({
  onChange,
  user,
}) => {
  const classes = useStyles({});

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(!user);
  }, [user]);

  const [searchTerm, setTerm] = useState('');

  const debouncedTerm = useDebounce<string>(searchTerm, 700);

  const { data, isLoading } = useGetUsers({
    query: debouncedTerm,
    skip: !debouncedTerm || debouncedTerm.length < 3,
  });

  return (
    <Box className={classes.root} display="flex">
      <Autocomplete
        fullWidth
        value={user}
        onChange={(e, value: IUser) => onChange && onChange(value)}
        options={data?.data || (user ? [user] : [])}
        getOptionLabel={(option: IUser) =>
          [
            option?.lastName,
            option?.firstName,
            option?.fatherName,
            option?.phoneNumber,
            option?.email,
          ]
            ?.filter(item => item)
            ?.join(' ')
        }
        onChangeTextField={e => setTerm(e.target.value)}
        label={'Пользователь'}
        loading={isLoading}
        actions={
          user?.id
            ? [
                <IconButton
                  size="small"
                  target="_blank"
                  href={routesMap.user.getRoute(user?.id)}
                  title="Открыть пользователя"
                  onClick={e => e.stopPropagation()}
                >
                  <Link />
                </IconButton>,
              ]
            : []
        }
      />
    </Box>
  );
};
