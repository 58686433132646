export const formatPrice = (number: number | null): string | null => {
  if (number === null) return number;
  return String(number).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
};

// use:
// declOfNum(['Яблоко','Яблока','Яблок'])(2); // Яблока
export const declOfNum = (titles: Array<string>) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return (passedNumber: number) => {
    const number = Math.abs(passedNumber);
    return titles[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  };
};
