import { List } from '@mui/material';
import React, { ReactNode } from 'react';
import { EnlityListItem } from './EnlityListItem';

interface Props {
  items?: any[];
  config?: {
    renderItem?: (props: { item: any; index: number }) => ReactNode;
  };
}

export const EntityList: React.FunctionComponent<Props> = ({
  items,
  config,
}) => {
  return (
    <List>
      {items?.map((item, index) => (
        <EnlityListItem
          key={item?.key}
          index={index + 1}
          item={item}
          config={config}
        />
      ))}
    </List>
  );
};
