import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Theme,
} from '@mui/material';
import { User as IUser, UserRole } from 'src/shared/api';
import { useGetOrders } from 'src/services/apiService';
import { changeUserRequest } from '../api';
import { EntityTitle } from 'src/components/EntityTitle';
import { Contractors } from 'src/components/Contractors';
import { Orders } from 'src/components/orders';

interface Props {
  user?: IUser;
  onChanage?: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'User' },
);

export const User: React.FunctionComponent<Props> = ({ user, onChanage }) => {
  const classes = useStyles({});

  const ordersRequest = useGetOrders({
    variables: { users: user?.id as number },
    skip: !user,
  });

  const handleLogin = () =>
    fetch(`${process.env.REACT_APP_API_HOST}/v1_api/users/me`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Switch-User': `${user?.phoneNumber}`,
      },
      redirect: 'manual',
      credentials: 'include',
    }).then(() => {
      //@ts-ignore
      window.location.href = 'https://instock-dv.ru/';
    });

  const handleChange = (data: Partial<IUser>) =>
    changeUserRequest(user?.id as number, data).then(onChanage);

  return (
    <div className={classes.root}>
      {user && (
        <>
          <EntityTitle
            id={user?.id}
            title={[user?.lastName, user?.firstName, user?.fatherName]
              .filter(item => item)
              .join(' ')}
          />
          <Paper>
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    value={user.phoneNumber}
                    label="Телефон"
                    variant="outlined"
                    disabled
                  />
                  <Box display="flex" mt={2}>
                    <Button
                      onClick={handleLogin}
                      variant="contained"
                      color="primary"
                    >
                      Войти как пользователь
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <List disablePadding>
                    {Object.values(UserRole).map(role => (
                      <ListItem
                        button
                        onClick={() =>
                          handleChange({
                            roles: user?.roles?.includes(role)
                              ? user?.roles?.filter(item => item !== role)
                              : [...(user?.roles || []), role],
                          })
                        }
                      >
                        <ListItemIcon>
                          <Checkbox checked={user?.roles?.includes(role)} />
                        </ListItemIcon>
                        <ListItemText primary={role} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <List disablePadding>
                    <ListItem
                      button
                      onClick={() =>
                        handleChange({
                          disabled: !user?.disabled,
                        })
                      }
                    >
                      <ListItemIcon>
                        <Checkbox checked={user?.disabled} />
                      </ListItemIcon>
                      <ListItemText primary="Заблокирован" />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <Box mt={2}>
            <Paper>
              <Contractors
                contractors={user?.contractors}
                limit={user?.contractors?.length}
                offset={0}
                onFetch={() => null}
                onChange={() => null}
                loading={false}
              />
            </Paper>
          </Box>
        </>
      )}
      <Box mt={2}>
        {ordersRequest.data?.data && (
          <Orders
            data={ordersRequest.data?.data}
            loading={ordersRequest.isLoading}
          />
        )}
      </Box>
    </div>
  );
};
