import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Theme,
  Typography,
  Toolbar,
  IconButton,
  TableHead,
  TableCell,
  Table as MuiTable,
  TablePagination,
  TableRow,
  TableBody,
  TableCellBaseProps,
  Checkbox,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { WithLoading } from 'src/components/common/WithLoading';
import clsx from 'clsx';
import Color from 'color';
import { orange } from '@mui/material/colors';

interface Props {
  onChange?: (params: { page: number; limit: number }) => any;
  onFetch?: () => any;
  onCancel?: any;
  loading: boolean;
  withPagination?: boolean;
  dense?: boolean;
  totalCount?: number | null;
  title?: string;
  onClickRow?: (item: any) => any;
  rowStyle?: (item: any) => any;
  headers: Array<{
    title: string;
    align?: 'left' | 'right' | 'center';
    extract?: (item: any, index?: number) => React.ReactNode;
    component?: React.ElementType<TableCellBaseProps>;
    scope?: TableCellBaseProps['scope'];
    warning?: (item: any) => boolean;
    summary?: React.ReactNode;
    onClick?: (e: React.SyntheticEvent, item: any) => any;
  }>;
  data?: any[] | null;
  limit?: number | null;
  offset?: number | null;
  withSummary?: boolean;
  actions?: ((params: { selectedItems: any }) => React.ReactNode)[];
}

type typeState = {
  limit: number;
  page: number;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      title: {
        flex: '1 1 100%',
      },
      warning: {
        backgroundColor: Color(orange[500]).alpha(0.1).string(),
      },
      muted: { color: theme.palette.grey[500] },
    }),
  { name: 'Table' },
);

export const Table: React.FunctionComponent<Props> = ({
  title,
  onFetch,
  withPagination = false,
  onChange,
  onCancel,
  loading,
  data = [],
  totalCount = 0,
  onClickRow,
  headers,
  actions,
  withSummary,
  dense,
  rowStyle,
  limit: providedLimit = 5,
  offset: providedOffset = 0,
}) => {
  const classes = useStyles({});

  const [state, changeState] = useState<typeState>({
    limit: 5,
    page: 0,
  });

  const [selectedItems, setSelectedItems] = useState<typeof data>([]);

  const setState = (data: Partial<typeState> = {}) => {
    const nextState = { ...state, ...data };

    if (onChange) {
      onChange(nextState);
    }

    changeState(nextState);
  };

  useEffect(() => {
    changeState({
      ...state,
      limit: providedLimit || 5,
      page:
        typeof providedOffset === 'number' && typeof providedLimit === 'number'
          ? providedOffset / providedLimit
          : 0,
    });
  }, [providedLimit, providedOffset]);

  return (
    <div className={classes.root}>
      {Boolean(title || onFetch) && (
        <Toolbar>
          <Typography className={classes.title} variant="h6" component="div">
            {title}
          </Typography>

          {actions?.map((action, index) => (
            <React.Fragment key={index}>
              {action({ selectedItems })}
            </React.Fragment>
          ))}
          {Boolean(onFetch) && (
            <IconButton
              onClick={() => {
                if (onFetch) {
                  onFetch();
                }
              }}
            >
              <Refresh />
            </IconButton>
          )}
        </Toolbar>
      )}
      <WithLoading loading={loading} onCancel={onCancel}>
        <MuiTable aria-label="table" size={dense ? 'small' : 'medium'}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.muted}>#</TableCell>
              {headers.map(header => {
                if (!header.extract) return null;
                if (header.title === 'checkbox') {
                  return (
                    <TableCell key={header.title}>
                      <Checkbox
                        checked={Boolean(
                          data?.length === selectedItems?.length,
                        )}
                        onChange={() =>
                          data?.length !== selectedItems?.length
                            ? setSelectedItems(data)
                            : setSelectedItems([])
                        }
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableCell key={header.title} align={header.align || 'left'}>
                    {header.title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item: any, index) => (
              <TableRow
                hover
                key={item.id || item.code}
                onClick={e => (onClickRow ? onClickRow(item) : null)}
                style={rowStyle ? rowStyle(item) : null}
              >
                <TableCell className={classes.muted}>{index + 1}</TableCell>
                {headers.map(header => {
                  if (!header.extract) return null;
                  if (header.title === 'checkbox') {
                    return (
                      <TableCell
                        key={header.title}
                        align={header.align || 'left'}
                      >
                        <Checkbox
                          checked={Boolean(
                            selectedItems?.find(
                              selectedItem => selectedItem.id === item.id,
                            ),
                          )}
                          onChange={() =>
                            selectedItems &&
                            selectedItems.find(
                              selectedItem => selectedItem.id === item.id,
                            )
                              ? setSelectedItems(
                                  selectedItems.filter(
                                    selectedItem => selectedItem.id !== item.id,
                                  ),
                                )
                              : selectedItems &&
                                setSelectedItems([...selectedItems, item])
                          }
                        />
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      onClick={e =>
                        header.onClick ? header.onClick(e, item) : null
                      }
                      align={header.align || 'left'}
                      key={header.title}
                      component={header.component}
                      scope={header.scope}
                      className={clsx(
                        header.warning &&
                          header.warning(item) &&
                          classes.warning,
                      )}
                    >
                      {header.extract(item, index)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            {withSummary && (
              <TableRow>
                <TableCell />
                {headers.map(header => (
                  <TableCell key={header.title} align={header.align || 'left'}>
                    {header.summary}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
        {withPagination && (
          <TablePagination
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} из ${count !== -1 ? count : `больше чем ${to}`}`
            }
            // labelRowsPerPage="элементов на странице"
            count={totalCount || 1}
            rowsPerPage={state.limit}
            page={state.page}
            rowsPerPageOptions={[]}
            onPageChange={(e, value) => setState({ page: value })}
          />
        )}
      </WithLoading>
    </div>
  );
};
