import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Theme,
} from '@mui/material';
import { Autocomplete } from 'src/components/common/Autocomplete';
import {
  useCreateAddresses,
  useGetSuggestionsAddresses,
} from 'src/api/addressApi';
import { useDebounce } from 'src/hooks/useDebounce';
import { Edit } from '@mui/icons-material';
import { WithLoading } from '../common/WithLoading';
import { typeDadataAddress } from 'src/types/appTypes';
import { Address } from 'src/shared/api';

interface Props {
  onSave: (address: Address) => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'AddressSelect' },
);

const validateAddress = (dadataAddress: typeDadataAddress) => {
  const requestedProperties = [
    'postal_code',
    'region_fias_id',
    'region_type_full',
    'region_type',
    'region',
    'region_with_type',
    'street_fias_id',
    'street_type_full',
    'street_type',
    'street',
    'street_with_type',
  ];

  requestedProperties.forEach(key => {
    // @ts-ignore
    if (!dadataAddress.data[key]) {
      throw new Error(`Missing propery "${key}"`);
    }
  });
};

export const AddressSelect: React.FunctionComponent<Props> = ({ onSave }) => {
  const classes = useStyles({});

  const [dialog, setDialog] = useState(false);
  const [value, setValue] = useState('');
  const [address, setAddress] = useState<any>(null);

  const query = useDebounce<string>(value, 750);

  const [getSuggestions, suggestionsResponse] = useGetSuggestionsAddresses();
  const [create, createAddressesResponse] = useCreateAddresses();

  useEffect(() => {
    if (query) {
      getSuggestions({ query, count: 8 });
    }
  }, [query]);

  const handleCreate = () => {
    if (address) {
      getSuggestions({ query: address?.unrestricted_value, count: 8 }).then(
        (res: { suggestions: typeDadataAddress[] }) => {
          const selectedAddress = res.suggestions[0];
          validateAddress(selectedAddress);

          create({
            postcode:
              selectedAddress.data.postal_code &&
              parseInt(selectedAddress.data.postal_code, 10),
            region: {
              fiasId: selectedAddress.data.region_fias_id,
              type: selectedAddress.data.region_type_full,
              shortType: selectedAddress.data.region_type,
              name: selectedAddress.data.region,
              nameWithType: selectedAddress.data.region_with_type,
            },
            area: selectedAddress.data.area_fias_id && {
              fiasId: selectedAddress.data.area_fias_id,
              type: selectedAddress.data.area_type_full,
              shortType: selectedAddress.data.area_type,
              name: selectedAddress.data.area,
              nameWithType: selectedAddress.data.area_with_type,
            },
            city: selectedAddress.data.city_fias_id
              ? {
                  fiasId: selectedAddress.data.city_fias_id,
                  type: selectedAddress.data.city_type_full,
                  shortType: selectedAddress.data.city_type,
                  name: selectedAddress.data.city,
                  nameWithType: selectedAddress.data.city_with_type,
                }
              : null,
            settlement: selectedAddress.data.settlement_fias_id
              ? {
                  fiasId: selectedAddress.data.settlement_fias_id,
                  type: selectedAddress.data.settlement_type_full,
                  shortType: selectedAddress.data.settlement_type,
                  name: selectedAddress.data.settlement,
                  nameWithType: selectedAddress.data.settlement_with_type,
                }
              : null,
            street: {
              fiasId: selectedAddress.data.street_fias_id,
              type: selectedAddress.data.street_type_full,
              shortType: selectedAddress.data.street_type,
              name: selectedAddress.data.street,
              nameWithType: selectedAddress.data.street_with_type,
            },
            houseNumber: selectedAddress.data.house,
            flatNumber: selectedAddress.data.flat,
            latitude: selectedAddress.data.geo_lat,
            longitude: selectedAddress.data.geo_lon,
          }).then((address: Address) => {
            onSave(address);
            setDialog(false);
          });
        },
      );
    }
  };

  return (
    <>
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Autocomplete
            fullWidth
            loading={suggestionsResponse.loading}
            label="Адрес"
            filterOptions={(options: any) => options}
            value={address}
            onChange={(e, value: any) => setAddress(value)}
            onChangeTextField={e => setValue(e.target.value)}
            options={suggestionsResponse.data?.suggestions || []}
            getOptionLabel={option => option?.unrestricted_value || ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Отмена</Button>
          <WithLoading
            loading={
              suggestionsResponse.loading || createAddressesResponse.loading
            }
          >
            <Button variant="contained" color="primary" onClick={handleCreate}>
              Сохранить
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
      <IconButton onClick={() => setDialog(true)} size="small" color="primary">
        <Edit />
      </IconButton>
    </>
  );
};
