import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Paper,
  Theme,
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@mui/material';
import { useRouter } from 'src/hooks/useRouter';
import { useGetPromotions } from 'src/services/apiService';
import { isAfter, isBefore } from 'src/helpers/date';
import { Promotions } from 'src/components/Promotions';
import { ContractorSelect } from '../ContractorSelect';
import { Contractor } from 'src/shared/api';
import { OutletSelect } from '../OutletSelect';
import { useDebounce } from 'src/hooks/useDebounce';

interface Props {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'PromotionsPage' },
);

export const PromotionsPage: React.FunctionComponent<Props> = ({}) => {
  const classes = useStyles({});
  const { push, query } = useRouter();

  const [state, setState] = useState({ page: 0, rowsPerPage: 5 });

  const [vendorId, setVendorId] = useState<null | string>(null);

  const debouncedVendorId = useDebounce(vendorId, 500);

  const [contractor, setContractor] = useState<null | Contractor>(null);

  const variables = {
    limit: query.limit || 13,
    offset: query.offset || 0,
    outlets: query.outlets,
    vendorId: query.vendorId,
  };

  useEffect(() => {
    const nextVariables = {
      ...variables,
      vendorId:
        debouncedVendorId !== null && debouncedVendorId !== query.vendorId
          ? debouncedVendorId
          : query.vendorId,
    };
    push(null, nextVariables);
  }, [debouncedVendorId]);

  useEffect(() => {
    if (query.vendorId !== vendorId) setVendorId(query.vendorId);
  }, [query]);

  const request = useGetPromotions({ variables });

  const toggleTimeQuery = (item: string) =>
    push(null, {
      time: query?.time?.includes(item)
        ? query?.time?.filter((i: string) => i !== item)
        : [item],
    });

  const data = request.data?.data?.filter(promotion => {
    const conditions = [];
    if (query?.time?.includes('past')) {
      promotion?.finish &&
        conditions.push(isBefore(new Date(promotion?.finish), new Date()));
    }

    if (query?.time?.includes('future')) {
      promotion?.start &&
        conditions.push(isAfter(new Date(promotion?.start), new Date()));
    }

    if (query?.time?.includes('present')) {
      promotion?.start &&
        promotion?.finish &&
        conditions.push(
          isBefore(new Date(promotion?.start), new Date()) &&
            isAfter(new Date(promotion?.finish), new Date()),
        );
    }

    return conditions.every(item => item);
  });

  return (
    <div className={classes.root}>
      <Paper>
        <Box px={2} py={1}>
          <Box>
            <ContractorSelect onSelect={setContractor} />
          </Box>
          <Box mt={2}>
            <OutletSelect
              onSelect={outlet => outlet && push(null, { outlets: outlet.id })}
              outlets={contractor?.outlets}
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              label="vendorId"
              value={vendorId}
              onChange={e => setVendorId(e.target.value)}
            />
          </Box>
        </Box>
      </Paper>
      {/* <Paper>
        <Box px={2} py={1}>
          <FormControlLabel
            label="Прошедшие"
            checked={query?.time?.includes('past')}
            onChange={() => toggleTimeQuery('past')}
            control={<Checkbox />}
          />
          <FormControlLabel
            label="Текущие"
            checked={query?.time?.includes('present')}
            onChange={() => toggleTimeQuery('present')}
            control={<Checkbox />}
          />
          <FormControlLabel
            label="Будущие"
            checked={query?.time?.includes('future')}
            onChange={() => toggleTimeQuery('future')}
            control={<Checkbox />}
          />
        </Box>
      </Paper> */}
      <Box mt={2}>
        <Promotions
          promotions={data}
          totalCount={request.data?.totalCount}
          refetch={request.refetch}
          isLoading={request.isLoading}
        />
      </Box>
    </div>
  );
};
