import { Button, Popover } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, useState, PropsWithChildren } from "react";

interface Props {
  confirmTitle?: string;
  cancelTitle?: string;
  onConfirm?: () => any;
  disabled?: boolean;
}

export const WithConfirm: FC<PropsWithChildren<Props>> = ({
  confirmTitle = "Подтвердить",
  cancelTitle = "Отмена",
  onConfirm,
  children,
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    setAnchorEl(null);
  };

  return (
    <>
      <div onClick={(e) => !disabled && setAnchorEl(e.currentTarget)}>
        {children}
      </div>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx={{}}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box display="flex">
          <Box mr={2}>
            <Button
              onClick={() => setAnchorEl(null)}
              size="small"
              variant="contained"
            >
              {cancelTitle}
            </Button>
          </Box>
          <Button
            color="secondary"
            onClick={handleConfirm}
            size="small"
            variant="contained"
          >
            {confirmTitle}
          </Button>
        </Box>
      </Popover>
    </>
  );
};
