import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Paper, Theme } from '@mui/material';
import { useProducts } from 'src/services/apiService';
import { useRouter } from 'src/hooks/useRouter';
import { Table } from 'src/components/common/Table';
import { Product } from 'src/types/api';
import { PorductPreview } from '../PorductPreview';
import { routesMap } from 'src/routes';
import { StockItemPrice } from '../StockItemPrice';
import { removeEmtyProperties } from 'src/helpers/removeEmtyProperties';
import { StockItemsDialog } from '../StockItemsDialog';

interface Props {
  query?: any;
  totalCount?: number;
  onChange?: any;
  withPagination?: boolean;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ProductsPage' },
);

export const ProductsPage: React.FunctionComponent<Props> = ({
  query = {},
  totalCount,
  onChange,
  withPagination,
}) => {
  const classes = useStyles({});
  const router = useRouter();

  const variables = removeEmtyProperties({
    manufacturers: router?.query?.manufacturers,
    brands: router?.query?.brands,
    query: router?.query?.query,
    limit: parseInt(router?.query?.limit, 10) || 50,
    offset: parseInt(router?.query?.offset, 10) || 0,
    ...query,
  });

  const productsRequest = useProducts({ variables });

  const handleOnChange = (params: { page: number; limit: number }) => {
    const nextRequestQuery = {
      ...variables,
      offset: params.page * params.limit,
      limit: params.limit,
    };

    onChange
      ? onChange(nextRequestQuery)
      : router.push(routesMap.products.getRoute(), nextRequestQuery);
  };

  return (
    <div className={classes.root}>
      <Paper>
        <Table
          dense
          data={productsRequest?.data?.data || []}
          headers={[
            {
              title: 'Товар',
              extract: (item: Product) => <PorductPreview product={item} />,
            },
            {
              title: 'Цена',
              align: 'right',
              extract: (item: Product) => {
                const minPriceItem = item?.stockItems?.sort((a, b) =>
                  a?.offerPrice &&
                  b?.offerPrice &&
                  a?.offerPrice > b?.offerPrice
                    ? -1
                    : 1,
                )?.[0];

                return (
                  <StockItemsDialog items={item?.stockItems}>
                    <StockItemPrice offerPrice={minPriceItem?.offerPrice} />
                  </StockItemsDialog>
                );
              },
            },
          ]}
          loading={productsRequest?.isLoading}
          onFetch={productsRequest?.refetch}
          totalCount={
            typeof totalCount === 'number'
              ? totalCount
              : productsRequest?.data?.totalCount
          }
          onChange={handleOnChange}
          limit={variables?.limit}
          offset={variables?.offset}
          withPagination={
            typeof withPagination !== 'undefined' ? withPagination : true
          }
        />
      </Paper>
    </div>
  );
};
