import {
  RequestHookParams,
  useRequest,
  Category,
  request,
  ResponseWithMeta,
} from 'src/shared/api';
import { RequestByLimitOffset } from 'src/shared/api/request';
import { apiPublicRoutesMap, apiRoutesMap } from 'src/shared/config';

export const useGetCategories = (
  params?: RequestHookParams<{ withEmpty?: boolean }>,
) => useRequest<Category[]>(apiPublicRoutesMap.categories.getRoute(), params);

export const createCategory = (category: {
  name: string;
  parent?: number;
  query?: string;
}) => request.post<any>(apiRoutesMap.categories.getRoute(), category);

export const changeCategory = (
  id: number,
  data: {
    name?: string;
    parent?: number;
    query?: string | null;
  },
) => request.patch<any>(apiRoutesMap.category.getRoute(id), data);

export const useGetCategoriesMissingProducts = (
  params?: RequestHookParams<RequestByLimitOffset>,
) =>
  useRequest<
    ResponseWithMeta<
      Array<{ bcode: number | null; code: number; name: string }>
    >
  >(apiPublicRoutesMap.categoriesMissingProducts.getRoute(), params);

export const categoriesRefresh = () =>
  request.post<any>(apiRoutesMap.categoriesRefresh.getRoute());
