import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  Theme,
  Button,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Fab,
  DialogTitle,
  Paper,
  Link,
} from '@mui/material';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useGetCategories } from 'src/entities/category';
import {
  categoriesRefresh,
  changeCategory,
  createCategory,
} from 'src/entities/category';
import { Add, Edit } from '@mui/icons-material';
import { Category } from 'src/shared/api';
import { routesMap } from 'src/routes';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'CategoriesPage' },
);

const EditCategoryDialog: React.FunctionComponent<{
  parent?: Category;
  category?: Category;
  afterCreate: () => any;
}> = ({ afterCreate, parent, category }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [query, setQuery] = useState<null | string>(null);

  useEffect(() => {
    if (category) {
      setName(category.name || '');
      setQuery(category.query || null);
    }
  }, [category, open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const reset = () => {
    setName('');
    setQuery(null);
    setLoading(false);
  };

  const handleSave = () => {
    category ? handleChange() : hanldeCreate();
  };

  const handleChange = () => {
    if (!category?.id) return null;
    const nextCategory: { name?: string; query?: string | null } = {};

    if (category?.name !== name && name) {
      nextCategory.name = name;
    }

    if (category?.query !== query) {
      nextCategory.query = query || null;
    }

    setLoading(true);
    // types=4

    changeCategory(category.id, nextCategory).then(() => {
      reset();
      afterCreate();
      setOpen(false);
    });
  };

  const hanldeCreate = () => {
    const nextCategory: { name: string; parent?: number } = { name };
    if (parent) {
      nextCategory.parent = parent.id;
    }

    setLoading(true);
    // types=4
    createCategory(nextCategory).then(() => {
      reset();
      afterCreate();
      setOpen(false);
    });
  };

  const disabled = loading;

  return (
    <>
      {parent && (
        <Fab color="primary" size="small" onClick={handleOpen}>
          <Add />
        </Fab>
      )}
      {!parent && !category && (
        <Button onClick={handleOpen} variant="contained" color="primary">
          Новая категория
        </Button>
      )}
      {category && (
        <Fab color="primary" size="small" onClick={handleOpen}>
          <Edit />
        </Fab>
      )}
      <Dialog open={open} onClose={handleClose}>
        {category && <DialogTitle>Изменить "{category.name}"</DialogTitle>}
        {parent && (
          <DialogTitle>Добавить подкатегорию к "{parent.name}"</DialogTitle>
        )}
        <DialogContent>
          <TextField
            value={name}
            onChange={e => setName(e.target.value)}
            fullWidth
          />
          <Box mt={2}>
            <TextField
              value={query}
              onChange={e => setQuery(e.target.value)}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={handleSave}
          >
            {category ? 'Сохранить' : 'Создать'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CategoriesList: React.FunctionComponent<{
  categories?: Category[];
  afterCreate: () => any;
  level: number;
}> = ({ categories, afterCreate, level }) => {
  return (
    <>
      <List disablePadding>
        {categories?.map(category => (
          <Fragment key={category.id}>
            <ListItem divider>
              <ListItemText
                primary={
                  <Link
                    target="_blank"
                    href={`${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products?categoriesInstock=${category.id}`}
                  >
                    {category.name}
                  </Link>
                }
                secondary={
                  category.query && (
                    <Link
                      target="_blank"
                      href={`${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products?${category.query}`}
                    >
                      {category.query}
                    </Link>
                  )
                }
              />
              <ListItemSecondaryAction>
                <Box display="flex">
                  {level < 3 && (
                    <EditCategoryDialog
                      afterCreate={afterCreate}
                      parent={category}
                    />
                  )}
                  <Box ml={2}>
                    <EditCategoryDialog
                      afterCreate={afterCreate}
                      category={category}
                    />
                  </Box>
                </Box>
              </ListItemSecondaryAction>
            </ListItem>

            <Box ml={4}>
              <CategoriesList
                categories={category.children}
                afterCreate={afterCreate}
                level={level + 1}
              />
            </Box>
          </Fragment>
        ))}
      </List>
    </>
  );
};

interface Props {}

export const CategoriesPage: React.FunctionComponent<Props> = ({}) => {
  const classes = useStyles({});

  const [loading, setLoading] = useState(false);

  const categoriesRequest = useGetCategories({
    variables: { withEmpty: true },
  });

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <EditCategoryDialog afterCreate={categoriesRequest.mutate} />
        <Box ml={2}>
          <Link
            target="_blank"
            href={routesMap.categoriesMissingProducts.getRoute()}
          >
            Товары без категорий
          </Link>
        </Box>
      </Box>
      <Box mt={4}>
        <Paper>
          <Box p={2}>
            <CategoriesList
              categories={categoriesRequest.data}
              afterCreate={categoriesRequest.mutate}
              level={1}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};
