import React from 'react';
import { PrintOrderDocumens } from './PrintOrderDocumens';
import { getZakazesDocumentsPrint } from '../api';

interface Props {
  guid: string;
}

export const PrintZakazesDocumens: React.FunctionComponent<Props> = ({
  guid,
}) => {
  return <PrintOrderDocumens guid={guid} fetcher={getZakazesDocumentsPrint} />;
};
