import { useState } from 'react';
import { PrintDocument } from 'src/shared/api';

export const useGetOrderDocuments = (
  fetcher: (guid: string) => Promise<any>,
  guid: string,
) => {
  const [documents, setDocuments] = useState<null | PrintDocument[]>(null);
  const [loading, setLoading] = useState(false);

  const handleGet = () => {
    setLoading(true);
    fetcher(guid).then(data => {
      setDocuments(data);
      setLoading(false);
    });
  };

  return { get: handleGet, documents, loading };
};
