import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Theme,
} from '@mui/material';
import { Users } from 'src/components/users';
import { Outlets } from 'src/components/Outlets';
import { Add } from '@mui/icons-material';
import { UserSelect } from '../user-select';
import { WithLoading } from 'src/components/common/WithLoading';
import {
  useGetContractor,
  apiService,
  useGetOrders,
} from 'src/services/apiService';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';
import { EntityTitle } from '../EntityTitle';
import { Orders } from '../orders';
import { Contractor, User, UserRole } from 'src/shared/api';
import { PermissionWrapper } from 'src/features/permission-wrapper';

interface Props {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'ContractorPage' },
);

export const ContractorPage: React.FunctionComponent<Props> = ({}) => {
  const classes = useStyles({});

  const [state, changeState] = useState<any>(null);
  const [dialog, setDialog] = useState<any>(false);
  const [user, setUser] = useState<User | null>(null);

  const setState = (data: Partial<Contractor>) =>
    changeState({ ...state, ...data });

  const { contractorId } = useParams<{ contractorId: string }>();

  const { data: contractor, isLoading } = useGetContractor({ contractorId });
  const ordersRequest = useGetOrders({
    variables: {
      outlets: contractor?.outlets?.map(item => item.id)?.join(','),
      state: 'processed',
    },
    skip: !contractor,
  });

  useEffect(() => {
    if (contractor) {
      changeState(contractor);
    }
  }, [contractor]);

  const handleUpdate = (users: string[]) => {
    if (contractor && users) {
      // update({
      //   variables: {
      //     input: {
      //       id: contractor.id,
      //       users,
      //     },
      //   },
      // }).then(() => {
      //   refetch(contractorQueryVariables);
      //   setDialog(false);
      // });
    }
  };

  const handleAdd = () => {
    if (contractor?.id && user) {
      apiService.v1Api
        .contractorsUsersCreate(`${contractor.id}`, user)
        .then(res => {
          setDialog(false);

          mutate([apiService.v1Api.contractorsDetail.name, contractorId]);

          return res.data;
        });
    }
  };

  const handleDelete = (e: React.SyntheticEvent, userfForDelete: User) => {
    e.stopPropagation();
    if (contractor && userfForDelete) {
      // handleUpdate(
      //   contractor.users
      //     ?.map(item => item?.id)
      //     .filter(item => item !== userfForDelete?.id) as string[],
      // );
    }
  };

  return (
    <div className={classes.root}>
      <EntityTitle id={contractor?.id} title={contractor?.name} />
      <Box mt={4}>
        <Users
          data={contractor?.users}
          totalCount={contractor?.users?.length}
          loading={isLoading}
          // itemActions={userfForDelete => (
          //   <IconButton
          //     size="small"
          //     color="primary"
          //     title="Удалить пользователя"
          //     onClick={e => handleDelete(e, userfForDelete)}
          //   >
          //     <Close />
          //   </IconButton>
          // )}
          actions={[
            () => (
              <PermissionWrapper roles={[UserRole.ROLE_ADMIN]}>
                <>
                  <IconButton
                    color="primary"
                    title="Добавить пользователя"
                    onClick={() => setDialog(true)}
                  >
                    <Add />
                  </IconButton>
                  <Dialog
                    open={dialog}
                    onClose={() => setDialog(false)}
                    maxWidth="sm"
                    fullWidth
                  >
                    <DialogContent>
                      <UserSelect user={user} onChange={setUser} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setDialog(false)}>Закрыть</Button>
                      <WithLoading loading={false}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={!user}
                          onClick={handleAdd}
                        >
                          Добавить
                        </Button>
                      </WithLoading>
                    </DialogActions>
                  </Dialog>
                </>
              </PermissionWrapper>
            ),
          ]}
        />
      </Box>
      <Box mt={4}>
        {/* <Orders
          data={contractor?.orders}
          totalCount={contractor?.orders?.length}
          loading={isLoading}
        /> */}
      </Box>
      <Box mt={4}>
        <Outlets
          data={contractor?.outlets}
          totalCount={contractor?.outlets?.length}
          loading={isLoading}
        />
      </Box>
      <Box mt={4}>
        <Orders
          data={ordersRequest?.data?.data}
          totalCount={ordersRequest?.data?.totalCount}
          loading={ordersRequest.isLoading}
        />
      </Box>
    </div>
  );
};
