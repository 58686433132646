export enum UserRole {
  ROLE_DELETED = 'ROLE_DELETED',
  ROLE_USER = 'ROLE_USER',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export interface User {
  id?: number;
  phoneNumber?: string;
  vendorId?: string | null;
  email?: string | null;
  contractors?: Contractor[];
  createdAt?: string;
  fatherName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  roles?: UserRole[];
  disabled?: boolean;
}

export interface Contractor {
  id?: number;
  inn?: string;
  name?: string;
  vendorId?: string;
  users?: User[];
  outlets?: Outlet[];
}

export interface Outlet {
  id?: number;
  vendorId?: string;
  contractor?: Contractor;
  address?: Address;
  createdAt?: string;
  updatedAt?: string;
}

export interface Address {
  /** Локальный идентификатор адреса в системе */
  id?: number;
  postcode?: number;
  region?: Region;
  area?: Area;
  city?: City | null;
  settlement?: Settlement | null;
  street?: Street;
  houseNumber?: string | null;
  flatNumber?: string | null;
  line?: string;
  longitude?: number;
  latitude?: number;
  locality?: AbstractAddressUnit;
}

export interface Region {
  id?: number;
  fiasId?: string;
  type?: string;
  shortType?: string;
  name?: string;
  nameWithType?: string;
}

export interface Area {
  id?: number;
  fiasId?: string;
  type?: string;
  shortType?: string;
  name?: string;
  nameWithType?: string;
}

export interface City {
  id?: number;
  fiasId?: string;
  type?: string;
  shortType?: string;
  name?: string;
  nameWithType?: string;
}

export interface Settlement {
  id?: number;
  fiasId?: string;
  type?: string;
  shortType?: string;
  name?: string;
  nameWithType?: string;
}

export interface Street {
  id?: number;
  fiasId?: string;
  type?: string;
  shortType?: string;
  name?: string;
  nameWithType?: string;
}

export interface AbstractAddressUnit {
  id?: number;
  fiasId?: string;
  type?: string;
  shortType?: string;
  name?: string;
  nameWithType?: string;
}

export interface Route {
  route: string;
  getRoute: (params?: any) => string;
  title?: string;
  roles: UserRole[];
}

export interface Article {
  id: number;
  createdAt: string;
  updatedAt: string;
  startTime: string;
  published: boolean;
  title: string;
  body: string | null;
}

export enum ArticlePropName {
  title = 'Заголовок',
  createdAt = 'Дата создания',
  startTime = 'Дата публикации',
  body = 'Контент',
  published = 'Опубликовано',
}

export interface PrintDocument {
  title: string;
  url: string;
}

export interface PhoneCall {
  answerTime: string;
  callId: number;
  caller: string;
  comment: string | null;
  createdAt: string;
  phonecallEvents: unknown;
  receiver: string;
  record: unknown;
  releaseTime: string;
  startTime: string;
  state: string;
  updatedAt: string;
}

export enum CallTaskPropName {
  name = 'Название',
  phonenumber = 'Номер телефона',
  createdAt = 'Дата создания',
  executor = 'Исполнитель',
  creator = 'Постановщик',
  comment = 'Комментарий',
  scheduleTime = 'Запланированное время звонка',
  closedTime = 'Время завершения задания',
}
export interface CallTask {
  id: number;
  createdAt: string;
  phonenumber: string;
  executor: User;
  creator: User;
  name: string;
  comment: string | null;
  phoneCall: PhoneCall | null;
  scheduleTime: string;
  closedTime: string;
  closed: boolean;
}

export interface ReportCofig {
  link: string;
  params: [
    {
      name: string;
      description: string;
      type: string;
    }[],
  ];
  description: string;
}

export interface Category {
  id?: number;
  name?: string;
  lft?: number;
  lvl?: number;
  rgt?: number;
  parent?: Category;
  description?: string;
  children?: Category[];
  query?: string;
}
