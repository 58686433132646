import React, { PureComponent, useMemo } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Theme, Typography } from '@mui/material';
import { Order } from 'src/types/api';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { format } from 'date-fns';
import { formatPrice } from 'src/helpers/formatting';
import _ from 'lodash';
import DownloadXlsx from './common/DownloadXlsx';
import { useGetOrders } from 'src/services/apiService';
import { typeOrder } from 'src/types/gqlTypes';
import { useRouter } from 'src/hooks/useRouter';

interface Props {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'OrdersCharts' },
);

class CustomizedLabel extends PureComponent {
  render() {
    //@ts-ignore
    const { x, y, stroke, value } = this.props;

    return (
      <text
        x={x}
        y={y}
        dy={-4}
        fill="rgba(255, 255, 255, 0.8)"
        fontSize={10}
        textAnchor="middle"
        color="#fff"
      >
        {formatPrice(value)}
      </text>
    );
  }
}

export const OrdersCharts: React.FunctionComponent<Props> = ({}) => {
  const classes = useStyles({});
  const router = useRouter();

  const request = useGetOrders({
    variables: { ...router.query, state: 'processed' },
  });

  const data: any = useMemo(
    () =>
      //@ts-ignore
      request?.data?.data &&
      Object.entries(
        request?.data?.data
          ?.map(({ data: order }) => ({
            ...order,
            updatedAt:
              order?.updatedAt && format(new Date(order?.updatedAt), 'yyyy-MM'),
          }))
          ?.reduce(
            //@ts-ignore
            (prev, curr: typeOrder) =>
              //@ts-ignore
              prev[curr?.updatedAt]
                ? {
                    ...prev,
                    //@ts-ignore
                    [curr?.updatedAt]: {
                      totalCost:
                        //@ts-ignore
                        prev[curr?.updatedAt].totalCost + curr?.totalCost,
                      //@ts-ignore
                      ordersCount: prev[curr?.updatedAt].ordersCount + 1,
                      //@ts-ignore
                      orders: [...prev[curr?.updatedAt].orders, curr],
                      contractors: {
                        //@ts-ignore
                        ...prev[curr?.updatedAt].contractors,
                        [curr?.outlet?.contractor?.id]:
                          curr?.outlet?.contractor,
                      },
                    },
                  }
                : {
                    ...prev,
                    //@ts-ignore
                    [curr?.updatedAt]: {
                      totalCost: curr?.totalCost,
                      ordersCount: 1,
                      orders: [curr],
                      contractors: {
                        [curr?.outlet?.contractor?.id]:
                          curr?.outlet?.contractor?.id,
                      },
                    },
                  },
            {},
          ),
      )
        ?.map((item: any) => ({
          updatedAt: item[0],
          ...item[1],
          totalCost: (item[1].totalCost as number) / 100,
          totalCostMedian:
            item[1]?.orders?.sort((a: typeOrder, b: typeOrder) =>
              a.totalCost > b.totalCost ? -1 : 1,
            )?.[Math.floor(item[1]?.orders?.length / 2)]?.totalCost / 100,
          contractorsCount: Object.keys(item[1].contractors)?.length,
          totalCostAvg: Math.floor(
            (item[1].totalCost as number) / item[1]?.orders?.length / 100,
          ),
        }))
        ?.sort((a, b) => (a?.updatedAt > b?.updatedAt ? 1 : -1)),
    [request?.data],
  );

  const monthes = useMemo(
    () =>
      _.uniq(
        _.map(
          request?.data?.data?.map(({ data: item }) => ({
            ...item,
            updatedAtMonth:
              item?.updatedAt && format(new Date(item?.updatedAt), 'yyyy-MM'),
          })),
          'updatedAtMonth',
        ),
      ),
    [request?.data?.data],
  );

  const xlsBycontractorAndMonth = useMemo(
    () =>
      Object.values(
        _.groupBy(
          request?.data?.data?.map(({ data: item }) => ({
            ...item,
            updatedAtMonth:
              item?.updatedAt && format(new Date(item?.updatedAt), 'yyyy-MM'),
          })),
          item => item.outlet?.contractor?.id,
        ),
      )?.reduce(
        //@ts-ignore
        (prev, curr) => [
          ...prev,
          {
            //@ts-ignore
            contractor: curr?.[0]?.outlet?.contractor?.name,
            user: curr?.[0]?.outlet?.contractor?.name,
            //@ts-ignore
            ...Object.entries(_.groupBy(curr, 'updatedAtMonth'))?.reduce(
              (prev, curr) => ({
                ...prev,
                //@ts-ignore
                [curr[0]]: prev[curr[0]]
                  ? //@ts-ignore
                    prev[curr[0]] +
                    //@ts-ignore
                    curr[1]?.reduce((prev, curr) => prev + curr?.totalCost, 0) /
                      100
                  : //@ts-ignore
                    curr[1]?.reduce((prev, curr) => prev + curr?.totalCost, 0) /
                    100,
              }),
              {},
            ),
          },
        ],
        [],
      ),

    [request?.data?.data],
  );

  if (!request?.data?.data) return <>Загрузка...</>;

  return (
    <div className={classes.root}>
      <Typography variant="h4">
        Продажи{' '}
        {formatPrice(
          data?.reduce((prev: any, curr: any) => prev + curr?.totalCost, 0),
        )}
        ₽
      </Typography>
      <LineChart width={1200} height={300} data={data}>
        <XAxis dataKey="updatedAt" />
        <YAxis dataKey="totalCost" />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <Line
          type="monotone"
          dataKey="totalCost"
          stroke="#8884d8"
          //@ts-ignore
          label={<CustomizedLabel />}
        />
        <Tooltip />
      </LineChart>
      <Typography variant="h4">Заказы</Typography>
      <LineChart width={1200} height={300} data={data}>
        <XAxis dataKey="updatedAt" />
        <YAxis dataKey="ordersCount" />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />

        <Line
          type="monotone"
          dataKey="ordersCount"
          stroke="#8884d8"
          //@ts-ignore
          label={<CustomizedLabel />}
        />
        <Tooltip />
      </LineChart>
      <Typography variant="h4">Контрагенты</Typography>
      <LineChart width={1200} height={300} data={data}>
        <XAxis dataKey="updatedAt" />
        <YAxis dataKey="contractorsCount" />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />

        <Line
          type="monotone"
          dataKey="contractorsCount"
          stroke="#8884d8"
          //@ts-ignore
          label={<CustomizedLabel />}
        />
        <Tooltip />
      </LineChart>
      <Typography variant="h4">Средний чек (медиана)</Typography>
      <LineChart width={1200} height={300} data={data}>
        <XAxis dataKey="updatedAt" />
        <YAxis dataKey="totalCostMedian" />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <Line
          type="monotone"
          dataKey="totalCostMedian"
          stroke="#8884d8"
          //@ts-ignore
          label={<CustomizedLabel />}
        />
        <Tooltip />
      </LineChart>
      <Typography variant="h4">Средний чек</Typography>
      <LineChart width={1200} height={300} data={data}>
        <XAxis dataKey="updatedAt" />
        <YAxis dataKey="totalCostAvg" />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <Line
          type="monotone"
          dataKey="totalCostAvg"
          stroke="#8884d8"
          //@ts-ignore
          label={<CustomizedLabel />}
        />
        <Tooltip />
      </LineChart>
      <Box>
        <DownloadXlsx
          title="Продажи по котрагентам и месяцам"
          disabled={!request?.data?.data}
          count={xlsBycontractorAndMonth.length}
          json={[
            {
              sheet: 'Sales',
              columns: [
                { label: 'Контрагент', value: 'contractor' },
                { label: 'Пользователь', value: 'user' },
                //@ts-ignore
                ...monthes
                  ?.reduce(
                    //@ts-ignore
                    (prev, curr) => [...prev, { label: curr, value: curr }],
                    [],
                  )
                  //@ts-ignore
                  ?.sort((a, b) => (a.label > b.label ? 1 : -1)),
              ],
              content: xlsBycontractorAndMonth,
            },
          ]}
        />
      </Box>
    </div>
  );
};
