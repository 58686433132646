import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import { formatPrice } from 'src/helpers/formatting';
import { green, red } from '@material-ui/core/colors';

interface Props {
  offerPrice?: number | null;
  singlePrice?: number | null;
  diff?: number | null;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        whiteSpace: 'nowrap',
      },
      singlePrice: {
        fontSize: theme.typography.pxToRem(11),
      },
      muted: {
        opacity: 0.5,
      },
    }),
  { name: 'StockItemPrice' },
);

export const StockItemPrice: React.FunctionComponent<Props> = ({
  offerPrice,
  singlePrice,
  diff,
}) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <div>
        <b>{offerPrice && formatPrice(offerPrice / 100)}&nbsp;₽</b>
      </div>
      {Boolean(singlePrice) && (
        <div className={clsx(classes.singlePrice, classes.muted)}>
          {singlePrice && formatPrice(singlePrice / 100)}
          &nbsp;₽&nbsp;за&nbsp;шт.
        </div>
      )}

      {Boolean(diff) && (
        <div
          style={{ color: diff && diff > 0 ? red[500] : green[500] }}
          title="Отличие от центы заказа клиента"
        >
          <b>
            {diff && diff > 0 && '+'}
            {diff && formatPrice(diff / 100)}&nbsp;₽
          </b>
        </div>
      )}
    </div>
  );
};
