import { useState } from 'react';
import { request } from 'src/services/request';
import { AxiosRequestConfig, Canceler } from 'axios';

export function useRequestWithPayload<RequestData, ResponseData>({
  url,
  method,
  options = {},
}: {
  url: string;
  method: keyof Pick<typeof request, 'post' | 'patch' | 'put'>;
  options: AxiosRequestConfig;
}): [
  (data?: RequestData) => any,
  {
    loading: boolean;
    error: any;
    data: ResponseData | null;
    cancel: Function;
  },
] {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<ResponseData | null>(null);

  const [error, setError] = useState(null);

  const [canceler, setCanceler] = useState<Canceler | null>(null);

  const cancel = () => {
    if (loading && canceler) {
      canceler(`${url} [${method.toUpperCase()}] canceled`);
    }
  };

  const fetch = async (data?: RequestData) => {
    if (!loading) {
      setLoading(true);

      const res = await request[method](url, data, {
        setCanceler: (method: any) => setCanceler(() => method),
        ...options,
      })
        .then(res => res?.data)
        .catch((error: any) => {
          console.error(error);

          setError(error);

          setLoading(false);

          return null;
        });

      setData(res);

      setLoading(false);

      setCanceler(null);

      return res;
    }

    setLoading(false);

    setCanceler(null);
  };

  return [
    fetch,
    {
      loading,
      error,
      data,
      cancel,
    },
  ];
}
