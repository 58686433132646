import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Link } from '@mui/material';
import { Product } from 'src/types/api';
import { routesMap } from 'src/routes';
import clsx from 'clsx';

interface Props {
  product?: Product | null;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
      },
      imageContainer: {
        width: '64px',
        height: '64px',
        marginRight: theme.spacing(2),
        '& > a': {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: '#fff',
          opacity: 0.9,
        },
        '& > a:hover': {
          opacity: 1,
        },
      },

      image: {
        width: 'auto',
        maxWidth: '64px',
        height: '64px',
      },
      details: {
        display: 'flex',
        flexDirection: 'column',
        '&:not(:first-child) > div': {
          marginTop: theme.spacing(0.5),
        },
      },
      item: {
        display: 'flex',
        '&>*': {
          marginRight: theme.spacing(1),
        },
      },
      title: {
        display: 'flex',
        alignItems: 'center',
      },
      brand: {
        opacity: 0.7,
        fontSize: theme.typography?.pxToRem(11),
        '&:hover': {
          opacity: 1,
        },
      },
      codes: {
        fontSize: theme.typography?.pxToRem(11),
        opacity: 0.7,
      },
    }),
  { name: 'Product' },
);

export const PorductPreview: React.FunctionComponent<Props> = ({ product }) => {
  const classes = useStyles({});

  if (!product) return null;

  const p = product;

  return (
    <div className={classes.root}>
      {p?.defaultImage && (
        <div className={classes?.imageContainer}>
          <a href={p?.defaultImage?.uri} target="_blank">
            <img
              className={classes?.image}
              src={`${p?.defaultImage?.uri}?w=50`}
              loading="lazy"
            />
          </a>
        </div>
      )}
      <div className={classes.details}>
        <div className={clsx(classes.item, classes.title)}>
          {p?.code && (
            <Link
              href={`${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products/${p?.code}`}
              target="_blank"
            >
              {p?.title}
            </Link>
          )}
        </div>
        <div className={classes.codes}>
          [{p?.code}] {product.barcodes?.join(', ')}
        </div>
        <div className={clsx(classes.item, classes.brand)}>
          {p?.manufacturer?.id && (
            <Link
              href={`${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products?manufacturers=${p?.manufacturer?.id}`}
              target="_blank"
              title={`Производитель ${p?.manufacturer?.name}`}
            >
              {p?.manufacturer?.name}
            </Link>
          )}
          {p?.brand?.id && (
            <Link
              href={`${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products?brands=${p?.brand?.id}`}
              target="_blank"
              title={`Бренд ${p?.brand?.name}`}
            >
              {p?.brand?.name}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
