import {
  RequestHookParams,
  ResponseWithMeta,
  request,
  useRequest,
} from 'src/shared/api';
import { apiPublicRoutesMap } from 'src/shared/config';
import { Product } from 'src/types/api';

export const changeSmatTaskProducts = (products: Array<number | string>) =>
  request.post<any>(apiPublicRoutesMap.smartTask.getRoute(), products);

export const useGetSmartTask = (params?: RequestHookParams<never>) =>
  useRequest<
    ResponseWithMeta<{ id: number; name: string; products: Product[] }>
  >(apiPublicRoutesMap.smartTask.getRoute(), params);
