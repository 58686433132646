import {
  CallTask,
  RequestHookParams,
  ResponseWithMeta,
  request,
  useRequest,
} from 'src/shared/api';
import { apiRoutesMap } from 'src/shared/config';

export const useGetCallTasks = () =>
  useRequest<ResponseWithMeta<CallTask[]>>(apiRoutesMap.callTasks.getRoute());

export const createCallTask = (data: Partial<CallTask>) =>
  request.post<any>(apiRoutesMap.callTasks.getRoute(), data);

export const useGetCallTask = (
  params: RequestHookParams<{ id: number | string }>,
) =>
  useRequest<ResponseWithMeta<CallTask>>(
    apiRoutesMap.callTask.getRoute(params.variables.id),
    params,
  );

export const changeCallTask = (id: number | string, data: Partial<CallTask>) =>
  request.patch<any>(apiRoutesMap.callTask.getRoute(id), data);

export const getPhonecallRecord = (id: number | string) =>
  request.get<any>(apiRoutesMap.phoneCallRecord.getRoute(id));
