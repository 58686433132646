import { useParams, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'querystring';
import { useMemo } from 'react';

const parseQueryItems = (queryObj: { [key: string]: string | string[] | undefined }) =>
  Object.entries(queryObj).reduce(
    (prev, curr) => ({
      ...prev,
      [curr[0]]: Array.isArray(curr[1]) ? curr[1] : curr[1]?.split(','),
    }),
    {},
  );

const stringifyQueryItems = (queryObj: { [key: string]: string | string[] }) =>
  Object.entries(queryObj).reduce(
    (prev, curr) => ({
      ...prev,
      [curr[0]]: Array.isArray(curr[1]) ? curr[1]?.join(',') : curr[1],
    }),
    {},
  );

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const push = useNavigate();

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: (
        path: string | null,
        query?: any,
        options: { merge: boolean } = { merge: false },
      ) => {
        const nextPath = path || location.pathname;

        return push(
          queryString.stringify(query)
            ? `${nextPath}?${queryString.stringify(stringifyQueryItems(query))}`
            : nextPath,
        );
      },
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...parseQueryItems(queryString.parse((location.search).replace('?', ''))), // Convert string to object
        ...params,
      } as any,
      location,
    };
  }, [params, location, push]);
}
