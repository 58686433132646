import React, { FC, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import { makeReserveRequest } from 'src/entities/order';

interface Props {
  zakazGuid?: string;
  zakazIdInternal?: string;
  onCreate: () => any;
}

export const MakeReserveButton: FC<Props> = ({
  zakazGuid,
  zakazIdInternal,
  onCreate,
}) => {
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState(false);

  const handleCreate = () => {
    setCreating(true);
    if (zakazGuid) {
      makeReserveRequest(zakazGuid)
        .then(() => {
          setFinished(true);
          setOpen(false);
          onCreate();
        })
        .catch(e => {
          setFinished(true);
          setError(true);
        });
    }
  };

  const handleOpen = () => setOpen(true);

  const handleClose = useMemo(() => {
    if (finished) {
      return () => setOpen(false);
    }
    return creating ? () => null : () => setOpen(false);
  }, [creating, finished]);

  return (
    <>
      <Button onClick={handleOpen} variant="contained" color="primary">
        Создать резерв
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <div>
            {!finished && (
              <>
                {!creating && (
                  <div>Создать резерв по заказу {zakazIdInternal}?</div>
                )}
                {creating && (
                  <div>Создаем резерв. Пожалуйста, не закрывайте страницу.</div>
                )}
              </>
            )}
            {Boolean(error) && (
              <div>Произошла ошибка. Пожалуйста, свяжитесь с менеджером</div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {!finished && (
            <Button
              onClick={handleCreate}
              disabled={creating}
              variant="contained"
              color="primary"
            >
              Создать
            </Button>
          )}
          <Button onClick={handleClose} disabled={creating && !error}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
