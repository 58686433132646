export function removeEmtyProperties(object: any): any {
  return Object.entries(object).reduce((acc, item) => {
    // @ts-ignore
    if (!item[1]) {
      return acc;
    }
    return { ...acc, [item[0]]: item[1] };
  }, {});
}

export const removeEmptyProps = (query: any) =>
  Object.keys(query).reduce<any>((obj, key) => {
    if (Array.isArray(query[key])) {
      const next = query[key].filter((item: any) => item);
      if (next?.length) obj[key] = next;
    }
    if (
      typeof query[key] !== 'undefined' &&
      query[key] !== '' &&
      query[key] !== null
    ) {
      obj[key] = query[key];
    }
    return obj;
  }, {});
